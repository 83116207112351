import { useEffect } from "react";
import BalloonBase from "../BalloonBase2025";

const Balloon2 = (props) => {
  const { hover2 } = props;
  useEffect(() => {
    document.body.style.cursor = hover2 ? "pointer" : "default";
  }, [hover2]);

  return <BalloonBase whichHover={hover2}>カーボンニュートラルに貢献する超速ゼニゴケ工学を活かしたビジネス創出</BalloonBase>;
};
export default Balloon2;
