const Projects = [
  {
    id: 1,
    url: "/project-2022/1",
    thumbnail: "/assets/models/textures/project_2022_01.jpg",
    category: "ライフサイエンス",
    adoptionYear: null,
    publicationPeriod: null,
    title: "Rasシグナルの網羅的阻害を基軸とした革新的がん治療薬の開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "科学技術イノベーション研究科",
    authorPosition: "教授",
    authorName: "島 扶美",
    portrait: "/assets/images/project2022/01/portrait.jpg",
    companion: null,
    alternatePresenter: null,
    video: {
      url: "https://youtu.be/nmWhmCX3Zzs",
      thumbnail: "/assets/images/project2022/01/video_thumbnail.jpg",
    },
    document: "/assets/demoday2023/documents/presentation_01.pdf",
    movieStart: "前半 0:19:05〜",
    overviewText: [
      <p>
        Rasのがん化シグナルにおいて重要な役割を果たす標的分子群の特定構造に着目し、当該構造の機能不全により、「活性型Rasによるがん化シグナル伝達阻害」を可能にするという革新的な創薬コンセプトを着想。
      </p>,
      <p>
        プロトタイプ化合物の合成展開を実施したところ、上市薬が奏功しない種々の活性型変異Rasを有するがん細胞に対して、顕著な細胞増殖抑制作用とRas下流の細胞内増殖シグナル伝達分子の活性化阻害作用を示した。
      </p>,
      <p>２種類のリード化合物は急性骨髄性白血病（HL60; N-RasQ61）ならびに小細胞肺がん（K- RasG12V）に対して担がんマウス試験で顕著な抗がん作用を示した。</p>,
    ],
    overviewImage: "/assets/images/project2022/01/01.jpg",

    comparisonText: [
      <p>
        Rasを標的とした抗がん剤開発の難易度は高い。上市例は現状ではマイナーなRas変異種に対する２剤（ソトラシブ、アダグラシブ）のみで治療充足度が極めて低い。
      </p>,
    ],
    propertyText: [
      <p>
        特願2021-125013、「Ras/Raf結合阻害化合物」
        <br />
        国立大学法人神戸大学、国立研究開発法人理化学研究所、
        <br />
        公益財団法人高輝度光科学研究センター
      </p>,
    ],
    taskText1: [
      <p>
        急性骨髄性白血病（AML）は悪性度が高く、生存率が極めて低い治療困難な血液がんの
        ひとつである。AMLではFLTキナーゼの下流でRasの活性化が認められる。また近年、AMLのブロックバスターであるベネトクラクス（BCL-2阻害剤）が上市されたが、早くもN-Ras活性化による薬剤耐性が指摘されている。そのため、Rasの機能を阻害する新薬の開発が必要とされている。
      </p>,
    ],
    taskText2: [<p>活性型Rasのシグナルをブロックする新規Rasシグナル伝達阻害剤の開発と上市</p>],
    taskText3: [
      <p>
        5年後生存率約28%のAML患者に対して有効な治療薬を提供することで生存率の向上に貢献する。（プロトタイプ化合物とその誘導体のがん細胞パネル試験では、N-Ras依存性シグナルの有意な阻害を確認済み。）
      </p>,
    ],
    taskText4: [<p>世界のAML市場規模：約930億円　CAGR4.8 (引用；MnM Knowledge Store)</p>],
    deployment: [
      <ul>
        <li>最も有望な母核誘導体を用いたがん細胞パネル試験の実施。Target Product Profileの追加設定</li>
        <li>特許追加収載化合物の合成とin vivo薬効の最適化（医薬品開発候補品の選定）</li>
        <li>臨床検体を使用した薬効評価（神戸大学病院・バイオバンクと調整中)</li>
      </ul>,
    ],
    strategy: [
      <ol>
        <li>特願2021-125013の追加。収載特許化合物のデータ収集</li>
        <li>周辺特許取得</li>
        <li>PCT出願</li>
      </ol>,
    ],
    futurePlanText: [
      <ul>
        <li>2023年度　START 事業プロモーター支援事業への申請予定</li>
        <li>2026年度　前臨床試験時　ベンチャー設立予定</li>
      </ul>,
    ],
  },
  {
    id: 2,
    url: "/project-2022/2",
    thumbnail: "/assets/models/textures/project_2022_02.jpg",
    category: "ライフサイエンス",
    adoptionYear: null,
    publicationPeriod: null,
    title: "癌幹細胞を標的とした新規癌ウイルス療法薬の開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "科学技術イノベーション研究科",
    authorPosition: "教授",
    authorName: "白川 利朗",
    portrait: "/assets/images/project2022/02/portrait.jpg",
    companion: null,
    alternatePresenter: null,
    video: {
      url: "https://youtu.be/j-F06FKpbfs",
      thumbnail: "/assets/images/project2022/02/video_thumbnail.jpg",
    },
    document: "/assets/demoday2023/documents/presentation_02.pdf",
    movieStart: "前半 0:39:51〜",
    overviewText: [
      <p>癌治療の最大の障壁はその再発・転移である。本研究では、癌の再発・転移の原因となる癌幹細胞を標的とした新規治療法を開発する。</p>,
      <ul>
        <li>「CD44」　細胞外マトリックスタンパクと結合するレセプター、癌幹細胞マーカーでもあり、がんの転移や浸潤に関連する。</li>
        <li>
          「HIF-３α４」　低酸素誘導因子（HIF-1α）は、低酸素状態で腫瘍細胞の多くの遺伝子を活性化し、腫瘍の増殖、転移および癌細胞の幹細胞化を亢進する。HIF-3α４は、HIF-1αに結合し、その機能を阻害する。
        </li>
        <li>
          現在までに、OVに搭載する治療遺伝子として、CD44のデコイ（おとり）レセプターとHIF-3α4をNotch core
          domainで結合させたCD44-N-HIF3α4融合遺伝子を創出し、強力な抗腫瘍効果を有することを確認した。
        </li>
        <li>治療遺伝子「CD44-N-HIF-3α４」を制限増殖型アデノウイルス（CRAｄ）搭載して新規OV「CRAd-CD44-N-HIF3α４」を開発する。</li>
      </ul>,
    ],
    overviewImage: "/assets/images/project2022/02/01.jpg",

    comparisonText: [
      <ul>
        <li>OVの競合技術としては、T-VEC（米アムジェン社）、HF10（タカラバイオ）、G47Δ（東京大学）などが治験進行中である。</li>
        <li>これらのうちT-VECは治療遺伝子を搭載しているが、癌幹細胞をターゲットとするものではない。</li>
      </ul>,
    ],
    propertyText: [
      <p>
        特願2019-239677「癌遺伝子治療薬」<br></br>発明者：白川利朗　他　出願人：神戸大学
      </p>,
    ],
    taskText1: [
      <p>
        近年、腫瘍細胞に感染し細胞融解やアポトーシス、さらには腫瘍免疫誘導を引き起こす腫瘍溶解性ウイルス（Oncolytic　virus: OV）もIO（Immuno-Oncology:
        IO）剤の一つのモダリティとして開発が進んでいる。しかし、癌治療の最大の障壁はその再発・転移である。本研究では、癌の再発・転移を制御し難治性癌の根治を目指すことが課題である。
      </p>,
    ],
    taskText2: [
      <p>
        In
        Situで強力な免疫誘導や腫瘍細胞融解性を発揮する制限増殖型アデノウイルス（CRAd）に、独自に開発した新規癌治療遺伝子（CD44-N-HIF3α４）を搭載した新規OVを開発する。新規癌治療遺伝子に用いるCD44は、癌幹細胞マーカーとして知られており、また、HIF3α４は、癌細胞の低酸素誘導因子の活性化を阻害し、細胞増殖や転移を強力に抑制するのみならず、CD44を高発現する転移性癌細胞、及び癌幹細胞を標的とする新規癌ウイルス療法薬を開発する。
      </p>,
    ],
    taskText3: [
      <p>
        CRAdの免疫誘導性、腫瘍細胞溶解性に加え、 CD44-N-HIF3α４の腫瘍増殖抑制効果・転移抑制効果を兼ね備え、さらに癌幹細胞をも標的とする癌根治療法を提供できる。
      </p>,
    ],
    taskText4: [
      <p>
        腫瘍溶解性ウイルス免疫療法市場は、2022年に86.43百万米ドルの市場価値から、2030年までに191.07百万米ドルに達すると推定され、2022－2030年の予測期間中に12.00％の年平均成長率で成長すると予想される。（SDKI
        Inc.）
      </p>,
    ],
    deployment: [
      <p>
        CRAd-CD44-N-HIF3α4を用いて非臨床有効性試験を実施する。
        <br />
        CRAd-CD44-N-HIF3α4作製し、ヒトTNBC細胞株XenograftモデルでIn vitroおよびIn
        vivo試験を実施し、制限増殖型アデノウイルスの効果向上を確認するとともに、CD44の高発現が確認されている前立腺癌や膵臓癌についてもXenograftモデルで有効性を確認する。
      </p>,
    ],
    strategy: [
      <ol>
        <li>2022年度 追加特許出願</li>
        <li>2024年度 神戸大学からベンチャーへライセンス許諾</li>
        <li>2027年度 ベンチャーから製薬会社へ特許技術を導出</li>
      </ol>,
    ],
    futurePlanText: [
      <ul>
        <li>2022年度：非臨床PoCを獲得</li>
        <li>2023年度：起業準備を進め、前臨床試験へと移行</li>
        <li>2024年度：AMED非臨床支援事業へ応募し前臨床試験の継続。AMED治験支援事業へとつなげ医師主導型PI試験を行う。ベンチャー起業</li>
        <li>2027年度：PII試験等条件付き早期承認を目指す。ベンチャーから大手製薬会社へ特許技術を導出</li>
        <li>2029年度：製造販売開始。</li>
      </ul>,
    ],
  },
  {
    id: 3,
    url: "/project-2022/3",
    thumbnail: "/assets/models/textures/project_2022_03.jpg",
    category: "ものづくり",
    adoptionYear: null,
    publicationPeriod: null,
    title: "構造発色する無機ナノ粒子の色材としての事業化の検討",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "工学研究科",
    authorPosition: "准教授",
    authorName: "杉本 泰",
    portrait: "/assets/images/project2022/03/portrait.jpg",
    companion: null,
    alternatePresenter: null,
    video: {
      url: "https://youtu.be/UIin6UrsXMs",
      thumbnail: "/assets/images/project2022/03/video_thumbnail.jpg",
    },
    document: "/assets/demoday2023/documents/presentation_03.pdf",
    movieStart: "前半 0:59:29〜",
    overviewText: [
      <ul>
        <li>染料や顔料に含まれる色素は自然環境中でいずれ分解されて退色する → 退色しない・重金属を用いない新規顔料を開発した</li>
        <li>Mie共鳴と呼ばれる特殊な光学共鳴により単独で構造色を示すナノ粒子</li>
        <li>低コストで環境負荷の小さい材料</li>
        <li>実用化に向けたスケールアップ技術と印刷性等の検証試験を実施</li>
        <li>独自シーズを基盤としてセキュリティ、コスメティクスからバイオ分野まで広い応用に向けた課題を解決し、事業化を目指す</li>
      </ul>,
    ],
    overviewImage: "/assets/images/project2022/03/01.jpg",
    comparisonText: [
      <ul>
        <li>従来次術の金属ナノ粒子より安価・資源が豊富、シリカ系粒子の構造色と違い印刷形態に依存しない発色</li>
        <li>富士フィルム等の構造色印刷技術に対して発色の角度依存性小さい</li>
      </ul>,
    ],
    propertyText: [
      <ul>
        <li>特願2019-200333</li>
        <li>Hiroshi Sugimoto, et al., Advanced Optical Materials, 8, 2000033 (2020)</li>
      </ul>,
    ],
    taskText1: [
      <ul>
        <li>① 交通標識の塗装が退色することによる視認性の低下がもたらす道路交通の危険性の上昇</li>
        <li>② 印刷技術の向上による偽造紙幣の出現</li>
        <li>③ 環境負荷の少ない輸送機塗料の必要性の高まり</li>
      </ul>,
    ],
    taskText2: [
      <ul>
        <li>① 構造色が持つ退色しないという特性を用いることで視認性を半永久的に維持し続ける</li>
        <li>② インクジェットプリントでは再現できないナノレベルでの紙幣印刷が可能になる</li>
        <li>③ 塗装にかかる塗料の量を削減でき、軽量化に貢献することで燃費を向上させる</li>
      </ul>,
    ],
    taskText3: [
      <ul>
        <li>① 視認性向上による標識見落としの削減。再塗装の手間の削減（≒行政負荷の削減）</li>
        <li>② 偽造紙幣の流通の防止</li>
        <li>③ 燃費向上と塗装の工数削減</li>
      </ul>,
    ],
    taskText4: [
      <ul>
        <li>①② 世界の蛍光顔料市場：約412億円</li>
        <li>③　 航空機用塗料市場：約4500億円</li>
      </ul>,
    ],
    deployment: [
      <ul>
        <li>（技術面）生産量のスケールアップ：10mg/日のペースで生産</li>
        <li>（技術面）複合化技術による色彩の向上：波長400-700nm、反射率20%以上</li>
        <li>（技術面）印刷・塗布技術：インクジェット印刷・印刷物の試作</li>
        <li>（事業面）社会実装に向けた具体的な分野の整理、ニーズ探索</li>
      </ul>,
    ],
    strategy: [
      <ol>
        <li>2022年度　追加特許出願</li>
        <li>ベンチャー設立後、神戸大学からベンチャーへライセンス許諾</li>
      </ol>,
    ],
    futurePlanText: [
      <ul>
        <li>2023年度：量産技術の内製化。 起業。</li>
        <li>2024年度：内製での量産開始。追加設備投資</li>
      </ul>,
    ],
  },
  {
    id: 4,
    url: "/project-2022/4",
    thumbnail: "/assets/models/textures/project_2022_04.jpg",
    category: "ライフサイエンス",
    adoptionYear: null,
    publicationPeriod: null,
    title: "革新的な疾患評価用バイオマーカーの開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "バイオシグナル総合研究センター",
    authorPosition: "教授",
    authorName: "今石 浩正",
    portrait: "/assets/images/project2022/04/portrait.jpg",
    companion: null,
    alternatePresenter: null,
    video: {
      url: "https://youtu.be/o-dkA-vDOaY",
      thumbnail: "/assets/images/project2022/04/video_thumbnail.jpg",
    },
    document: "/assets/demoday2023/documents/presentation_04.pdf",
    movieStart: "前半 1:17:08〜",
    overviewText: [
      <p>
        体外から取り込まれた薬物をはじめ、様々な物質の酸化反応を触媒する薬物代謝酵素シトクロムP450は、様々な種類の疾患の発症時において、体内での発現量が変化することが知られている。
      </p>,
      <p>これらP450の発現量の変化によって、患者の体内ではP450に関連する物質の量や質が変化すると考えられる。</p>,
      <p>本研究では、疾患の発症によって生体内に生じたP450関連の物質量やそれらの質を簡便に検出する方法、「P450蛍光阻害アッセイ法」を確立した（特許）。</p>,
      <p>今回、この手法をパーキンソン病やうつ病、がんなどの炎症性疾患の診断へと適用することに世界で始めて成功した。</p>,
      <p>
        本研究で開発した「P450蛍光阻害アッセイ法」は、健康な人や患者から採取した血清と12種類のP450、蛍光性基質をそれぞれ混合することで反応を行わせる。このとき、健康な人と患者の血清に含まれるP450に関連する物質の量や質が変化するが、「P450蛍光阻害アッセイ法」はこの変化を検出することで疾患の有無を判別する、これまでになかった新しいリキッドバイオプシー技術である。
      </p>,
    ],
    overviewImage: "/assets/images/project2022/04/01.jpg",
    comparisonText: [
      <p>
        現在、パーキンソン病やうつ病の診断には、パーキンソニズムの有無や主に精神科医によるICD-11（第11回国債疾病分類）に基づいた分類が用いられているが、本文類は必ずしも発症初期の患者に対する有効なスクリーニング検査とはなっていない。よって、良質なバイオマーカーが望まれている。
      </p>,
    ],
    propertyText: [
      <ul>
        <li>疾患のバイオマー カーの検出方法、検出装置 特願2020-065362</li>
        <li>炎症性疾患のバイオ マーカー 特許第6501607号</li>
        <li>
          Diagnosis of Parkinson's disease by investigating the inhibitory effect of serum components on P450 inhibition assay, Scientific Reports, 12, 6622
          (2022){" "}
        </li>
      </ul>,
    ],
    taskText1: [
      <p>
        近年、高齢化の影響により日本国内のパーキンソン病患者数が増加している。パーキンソン病患者の重症度は、既存の技術では把握しづらく早期の適切な治療介入が難しい。また、コロナ禍によりうつ病を発症する患者数も増加傾向にある。
      </p>,
    ],
    taskText2: [<p>上記、社会課題をP450蛍光阻害アッセイ法を用いることでパーキンソン病患者およびうつ病の診断が可能な診断サービスを提供する。</p>],
    taskText3: [
      <p>
        本技術を用い重症度を数値化することで「パーキンソン病やうつ病の重症度に合わせた適切な対応」「両疾患の治療効果の把握」「治療薬の選択」などが可能となり、適切な治療方法の選択に対して貢献する。
      </p>,
    ],
    taskText4: [
      <p>
        国内パーキンソン病診断市場700億円[潜在的パーキンソン病患者数100万人x7000円販売価格x10回]、国内うつ病診断市場1520億円[日本人口xうつ病疾患者割合17%x7000円販売価格]。また本手法はがん診断も可能であることから、更に市場規模は大きくなることが期待される。
      </p>,
    ],
    deployment: [
      <p>【技術】</p>,
      <ol>
        <li>サンプル数の確保</li>
        <li>実診断へ対応するための性能評価</li>
        <li>酵素活性の安定化技術実用化</li>
      </ol>,
      <br />,
      <p>【事業】</p>,
      <ol>
        <li>市場調査</li>
        <li>薬事承認に関する情報収集</li>
        <li>最適な疾患市場の決定</li>
        <li>連携臨床医の探索</li>
      </ol>,
    ],
    strategy: [
      <ol>
        <li>2023年度までに新たな国際特許の出願を目指す。</li>
      </ol>,
    ],
    futurePlanText: [
      <ul>
        <li>2023年度中に集中する事業を決定する。</li>
        <li>2023年度にベンチャー化を目指す。</li>
      </ul>,
      <p>* 対象疾患の決定と医師主導の臨床試験を実施する必要があるためベンチャー設立が申請書よりも遅れる可能性あり</p>,
    ],
  },
  {
    id: 5,
    url: "/project-2022/5",
    thumbnail: "/assets/models/textures/project_2022_05.jpg",
    category: "ライフサイエンス",
    adoptionYear: null,
    publicationPeriod: null,
    title: "がんや認知症を防ぐマイオカインを高濃度に含む培養食肉カプセル",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "工学部生命工学科",
    authorPosition: "教授",
    authorName: "藤里 俊哉",
    portrait: "/assets/images/project2022/05/portrait.jpg",
    companion: null,
    alternatePresenter: null,
    video: {
      url: "https://youtu.be/bABRLF9zP-s",
      thumbnail: "/assets/images/project2022/05/video_thumbnail.jpg",
    },
    document: "/assets/demoday2023/documents/presentation_05.pdf",
    movieStart: "後半 0:00:31〜",
    overviewText: [
      <p>
        運動する筋肉は、がんや認知症を防ぐ効果をもつマイオカインというホルモン群を分泌している。代表者らが開発した培養筋肉（OITem、図1、関係論文1）を運動させ、おいしく高機能な培養食肉、いわば培養高級地鶏肉を開発する。
      </p>,
      <p>OITemは特徴として腱構造をもつため（保有知財）、容易に運動させることができる（図2） 。</p>,
      <p>
        培養筋肉は畜産肉に比べ、おいしさに欠けることが報告されているが、運動させることで改善される。例えば、うまみ成分のイノシン酸はエネルギー代謝の増大で増加する。
      </p>,
      <p>
        OITemも運動させるとマイオカインを分泌するが（関係論文2）、培養液中に拡散してしまう。周囲をカプセルで覆うことでマイオカインを留め置き、高機能培養食肉とする（図3、本研究の成果として出願）。
      </p>,
    ],
    overviewImage: "/assets/images/project2022/05/01.jpg",
    comparisonText: [
      <p>
        現在、世界中で開発されている培養食肉のほとんどは挽肉状の培養挽肉である。単なる低価格大量生産競争となっており、栄養価や機能性は全く考慮されていない。
      </p>,
      <p>
        一部、ステーキ状の培養ステーキ肉が開発されているが、畜産肉の霜降り構造などの構造再現が重視されており、運動は考慮されていない、いわば培養ブロイラー肉である。
      </p>,
    ],
    propertyText: [
      <ul>
        <li>知財：生物由来スキャフォールドの作製方法</li>
        <li>出願：ゲル膜に覆われてなる培養細胞塊および作製方法</li>
        <li>論文1：J Biosci Bioeng. 2017; 123: 265</li>
        <li>論文2：Int J Mol Sci. 2022; 23: 5723</li>
      </ul>,
    ],
    taskText1: [
      <p>
        地球人口の増加に伴って食肉需給の逼迫が想定されるにも拘わらず、家畜による温室効果ガス（メタン）
        の排出、広大な牧畜面積の必要性、などから畜産環境は厳しい。加えて、動物愛護による屠殺の忌避、健康志向の高まり、などから畜産肉に代わり、安全で高機能な培養食肉が求められている。
      </p>,
    ],
    taskText2: [
      <p>
        本技術によって、世界中で開発競争されている培養挽肉ではなく、がんや認知症を防ぐ効果をもつマイオカインを豊富に含む上に、おいしい培養食肉を開発し、上記の社会課題を一挙に解決する。
      </p>,
    ],
    taskText3: [
      <p>
        培養食肉の開発は数多くあるが、本提案のような運動させてマイオカインを高濃度含む培養食肉は、これまでにない培養食肉の新たなアプローチである。食べることで、がんや認知症を防ぐことができれば、その効果は多大であり、また、容易にマイオカインを回収できることから、サプリメントや医薬品用途への応用もたやすい。さらに、重要な点であるが、筋組織以外の組織に応用できる可能性も十分に高い。
      </p>,
    ],
    taskText4: [
      <p>
        2040年には60%が代替肉になると予想されており、うち半数は培養食肉と予測されている（世界市場70兆円）。高機能培養食肉の市場規模は予測しがたいが、10%としても7兆円である。本研究では、当初はサプリメントとしての商品化を想定しており、市場規模は国内1兆円程度である。
      </p>,
    ],
    deployment: [
      <ul>
        <li>現在のOITemはマウス細胞を使用しているため、鶏および牛細胞に変更するとともに、品質向上のためにバイオ3Dプリンタを導入する。</li>
        <li>マイオカインを留め置く至適条件を決定する。</li>
        <li>マイオカイン測定および栄養価評価を通じて、運動長、周期、時間、頻度などについて、少なくとも複数のマイオカイン分泌至適条件を決定する。</li>
      </ul>,
    ],
    strategy: [
      <ol>
        <li>本研究の成果として先日出願した特許を基本に据える。</li>
        <li>食品・医薬品企業に技術供与することで商品化を目指す。</li>
      </ol>,
    ],
    futurePlanText: [
      <ul>
        <li>2024年度：大学発のスタートアップ設立を目指す。</li>
        <li>2027年度：機能性食品、サプリメントとしての商品化を目指す。</li>
      </ul>,
    ],
  },
  {
    id: 6,
    url: "/project-2022/6",
    thumbnail: "/assets/models/textures/project_2022_06.jpg",
    category: "ものづくり",
    adoptionYear: null,
    publicationPeriod: null,
    title: "燃料電池の原理(電解法)を用いたバリアフィルムの酸素および燃料分子の透過・拡散係数の評価装置",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "工学部生命工学科",
    authorPosition: "客員教授",
    authorName: "金藤 敬一",
    portrait: "/assets/images/project2022/06/portrait.jpg",
    companion: null,
    alternatePresenter: null,
    video: {
      url: "https://youtu.be/vvIiHhERn_c",
      thumbnail: "/assets/images/project2022/06/video_thumbnail.jpg",
    },
    document: "/assets/demoday2023/documents/presentation_06.pdf",
    movieStart: "後半 0:19:33〜",
    overviewText: [
      <p>
        燃料電池は、図１に示すセル内に供給する燃料と酸素を反応させることによって外部回路に電力を取り出す装置で、出力電流（i）は燃料・酸素の供給量と負荷抵抗によって決まる。
      </p>,
      <p>例えば、負荷抵抗を十分小さく燃料を十分供給しておくと、 iは酸素の供給量によって決まる。</p>,
      <p>そこで、酸素の供給をバリアフィルムで塞ぐと、図２で示すレベルオフ電流(i∞)からバリアフィルムを透過する酸素量が直接求められる。</p>,
      <p>即ち、i∞から (1)式を用いてバリアフィルムの酸素の透過係数が得られる。これは等圧法で、実際の酸素の透過状況と同じである。</p>,
      <p>表１はグルコース燃料電池を用いて求めた酸素透過係数　(P)で、既報値とよく一致し、本方法の有用性を示す。</p>,
    ],
    overviewImage: "/assets/images/project2022/06/01.jpg",
    comparisonText: [
      <p>
        食品の長期保存、医薬品や電子素子などを酸化劣化から守るガスバリアーフィルムは、産業に不可欠な要素技術となっている。モコン法を始め国際的に標準化（ISO15105
        (JISK7126)されている。
      </p>,
      <p>
        モコン法では、バリアーフィルムを透過した酸素をキャリア―ガスによって
        (1)ガスクロマトグラフィー、(2)ポーラログラフ、(3)クーロメトリー法によって検出し、間接的に透過係数を算出する。これらの装置はいずれも大掛かりで高価である。
      </p>,
      <p>
        本提案の測定方法は、キャリアガスが不要で、電解電流のレベルオフ値から直接透過係数が求められる。ISOには記載されていない新規な方法であり、本装置は安価に提供でき、簡便かつ短時間で計測できる。
      </p>,
    ],
    propertyText: [
      <ul>
        <li>保有知財： 特願2021-204089　「リークディテクター・及び気密部材のリークの検出方法」</li>
        <li>
          <a href="http://id.nii.ac.jp/1360/00000481/" target="_blank">
            関係論文: ”燃料電池によるバイオ分子の拡散と酸素の透過係数の評価” 大工大紀要 Vol.65, No.1 (2020) pp.1-9.
          </a>
        </li>
        <li>
          関係論文： "電解式O<sub>2</sub>ポンプによるバリアフィルムの酸素透過率測定" 大工大紀要 Vol.67,No.2 (2022) PP.79〜84.
        </li>
      </ul>,
    ],
    taskText1: [
      <p>
        食品、医薬品、電子デバイスなどの流通のグローバル化対応、ロス軽減のための長期保存はSDGsに不可欠である。従って、これらの商品に必要な包装資材の高分子バリアフィルムは今後需要が増すと共に、更なる軽量・薄膜化、高品質化が要求されている。
      </p>,
    ],
    taskText2: [
      <p>
        包装資材であるバリアーフィルムは素材メーカーではバリア性の品質の向上、また、それを用いて商品を梱包する食品・試薬・電子メーカーは梱包過程で気密チェックなどが要求されている。これらには酸素ガスなどの透過率検査は不可欠で、本装置により簡便、短時間かつ高精度で、しかも製造ライン上で直接検査できる。
      </p>,
    ],
    taskText3: [
      <p>
        本装置は現行商品と比較し、安価に提供でき、低コストで簡便な検査方法により品質管理が容易にできるため、製品の品質向上はもとより　商品のコストダウンにも貢献できる。
      </p>,
    ],
    taskText4: [
      <p>
        酸素などガス透過率の測定は、現在、モコン法が世界標準として独占的に市場を支配している。HH社がモコン社の国内代理店として、５百億円と言われる国内市場の70%のシェアを保有している。世界市場は小さく見積もってその十倍以上と推定できる。
      </p>,
    ],
    deployment: [
      <ul>
        <li>通常のバリアフィルム>10-10 cm3(STP)cm/cm2 s cmHgにおける酸素透過係数評価装置のプロトタイプを製作。</li>
        <li>本事業では、この10倍以上のハイバリアフィルムにおける酸素透過係数を評価できる測定感度の向上を達成。</li>
        <li>燃料電池の原理に電解式酸素ポンプを併用した、業界初の高感度透過係数測定装置の完成を目指す。</li>
        <li>将来的には酸素以外の燃料分子の透過係数を評価する装置の開発も検討予定。</li>
      </ul>,
    ],
    strategy: [
      <ol>
        <li>電解式バリアフィルムの透過係数評価装置および方法に関する知財資料を作成。</li>
        <li>本学より特許もしくはISOへ技術の申請予定。</li>
      </ol>,
    ],
    futurePlanText: [
      <ul>
        <li>2023年度：学内ベンチャーを立ち上げ、共同研究開発のパートナー企業を決定。製品化を加速。</li>
        <li>2025年度：パートナー企業と酸素透過係数測定装置を量産化を実現し、事業開始を予定。</li>
      </ul>,
    ],
  },
  {
    id: 7,
    url: "/project-2022/7",
    thumbnail: "/assets/models/textures/project_2022_07.jpg",
    category: "ライフサイエンス",
    adoptionYear: null,
    publicationPeriod: null,
    title: "患者のQOL向上を目指した、新規生体類似材料を用いた、異物付着抑制効果を有した尿管ステントの研究",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "保健学研究科・医学部泌尿器科",
    authorPosition: "准教授",
    authorName: "重村 克巳",
    portrait: "/assets/images/project2022/07/portrait.jpg",
    companion: null,
    alternatePresenter: null,
    video: {
      url: "https://youtu.be/_Jcjgm0FChU",
      thumbnail: "/assets/images/project2022/07/video_thumbnail.jpg",
    },
    document: "/assets/demoday2023/documents/presentation_07.pdf",
    movieStart: "後半 0:38:01〜",
    overviewText: [
      <p>
        尿管ステントへの異物付着の問題とメカニズムを熟知する医学者（申請者）が工学者の協力を得て、「生体にやさしい材料を用いて、より効果的な尿管ステント表面の改質」を目的として新規尿管ステントの創出を行う。
      </p>,
      <p>生体物質にヒントを得たコーティング剤：特殊コーティングをステントに施して、体内の異物反応を減らし、異物低付着性を実現させる。</p>,
      <p>
        最も患者さんへの害が大きいとされる結石の付着をいかに抑止し、既製品よりも長期間安全に留置でき、留置中の閉塞による感染症や腎機能低下の頻度も軽減する新規ステント創出を目指す。
      </p>,
      <p>表面物性、構造と異物付着抑制効果、生体適合性等との関係性について学術的基盤を構築し、尿管ステントの設計指針を確立する。</p>,
    ],
    overviewImage: "/assets/images/project2022/07/01.jpg",
    comparisonText: [<img src="/assets/images/project2022/07/02.jpg" />],
    propertyText: [
      <p>
        「医療デバイスの結石付着又は石灰化の抑制化剤、結石付着又は石灰化が抑制化された医療デバイス、
        及び結石付着又は石灰化が抑制化された医療デバイスの製造方法」 (PCT/JP2022/005850)
      </p>,
    ],
    taskText1: [
      <p>
        全世界の尿路結石患者数は約8,062万人と言われており、1990年と比較すると約2倍に増加している。
        尿管ステントへの結石付着に伴う尿路感染症や副作用に苦しむ患者も多く、ステント留置中患者のうち80％以上が合併症を発症している。
      </p>,
    ],
    taskText2: [
      <p>従来技術とは異なり、分離膜で実績のある表面コーティング技術を尿管ステントに応用することで、ステント留置患者の合併症リスクを抑えQOL向上を目指す。</p>,
    ],
    taskText3: [
      <p>
        従来技術と比べ、本コーティング技術を用いたステントは閉塞を起こしにくく、合併症（感染症）や腎不全の発症リスクを軽減することができる。伴って、ステントの交換頻度や治療に係るコストを削減し、医療費の軽減に寄与する。
      </p>,
    ],
    taskText4: [
      <p>
        尿管ステントへの結石付着と、それに伴う尿路感染症などの副作用に苦しむ患者さんは多い。（全世界の尿路結石患者数は8,062万人であり，1990年と比較して約2倍に増加）
        尿管ステントの全世界の市場規模は2020年で4.5億ドルから、2027年では7億ドルまでの拡大が見込まれている。
      </p>,
    ],
    deployment: [
      <p>【研究開発】</p>,
      <ol>
        <li>本コーティングステントの更なる安定化＆適正化</li>
        <li>大型動物を用いた結石付着予防効果ならびに安全性の検討</li>
      </ol>,
      <br />,
      <p>【ビジネスモデル】</p>,
      <ol>
        <li>顧客ニーズ把握のための市場調査</li>
        <li>共同開発候補企業の選定＆アプローチ</li>
        <li>PMDAへ相談を開始する</li>
      </ol>,
    ],
    strategy: [
      <ol>
        <li>コーティング素材や条件の最適化をし、周辺知財の獲得を目指す</li>
        <li>他医療デバイスについても出願</li>
        <li>海外における権利化も目指す</li>
      </ol>,
    ],
    futurePlanText: [
      <ul>
        <li>大型動物における本開発ステントの効果＆安全性が認められ次第起業予定（2026年頃を目指す）</li>
        <li>GLP試験＆臨床試験は起業後、共同開発企業とともに行う。</li>
      </ul>,
    ],
  },
];

export default Projects;
