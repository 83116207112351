import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import SpeakerList from "../../../data/Project2024";
import overlay from "../images/overlay_03.png";
import bg from "../images/background_speakers.jpg";

const Speakers = () => {
  // 先生を名前順に並び替えるための変数
  const orderId = [2, 5, 6, 1, 4, 3];
  const sortedProjects = SpeakerList.sort((a, b) => orderId.indexOf(a.id) - orderId.indexOf(b.id));

  return (
    <SSpeakers id="speakers" className="flex">
      {/* <img src="./assets/demoday2023/images/circle_03.svg" alt="" className="circle-03" /> */}

      <div className="col-left">
        <div className="title-en">SPEAKERS</div>
      </div>
      <div className="col-right">
        <div className="title-box">
          <h2>
            登壇者情報<span style={{ fontSize: "0.7em" }}>（五十音順）</span>
          </h2>
        </div>

        <div className="speaker-list flex">
          {sortedProjects.map((speaker, index) => (
            <div key={index} className="item col-4 flex">
              <div className="flex">
                <a className="image-box" href={speaker.url}>
                  <img src={speaker.portrait} alt="" />
                  <span></span>
                </a>
                <div className="desc">
                  <h4>
                    {speaker.title}
                    <span style={{ display: "block", marginTop: "0.1em" }}>{speaker.subtitle}</span>
                  </h4>
                  <div className="inner">
                    <h3>
                      <span class="author-name">{speaker.authorName}</span>
                      <span class="author-en-name">{speaker.authorEnName}</span>
                    </h3>
                    <h4>
                      {speaker.authorUniversity && <span>{speaker.authorUniversity}</span>}
                      {speaker.authorAffiliation && <span>{speaker.authorAffiliation}</span>}
                      {speaker.authorPosition && <span>{speaker.authorPosition}</span>}
                    </h4>
                    {speaker.companion && <div className="companion">{speaker.companion}</div>}
                    {speaker.alternatePresenter && <div className="alternate-presenter">{speaker.alternatePresenter}</div>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="commentator">
          <h3>コメンテーター</h3>
          <div class="item">
            <div className="flex">
              <img src={`${process.env.PUBLIC_URL}/assets/demoday2024/images/mori.jpg`} alt="森 智世" />
              <div class="desc">
                <p style={{ fontWeight: "bold" }}>
                  株式会社ファストトラックイニシアティブ
                  <br />
                  Venture Capitalist, M.D.
                </p>
                <h4>
                  森 智世<span>Tomoyo MORI</span>
                </h4>
                <p>
                  医師免許取得後、医療過疎地での研修・診療に従事。その後東京女子医科大学病院循環器内科に入局し、心不全、心臓移植、心筋梗塞の患者の治療を行う。大学病院では医療機器スタートアップの治験を担当。臨床と並行して医療機器スタートアップであるAMI株式会社に参画し、国内および海外事業展開に携わる。米国事業部責任として米国のMedTechアクセレレーターである、MedTech
                  Innovator, Fogarty Innovationに日本企業として初めて選出さた経験を持つ。
                  <br /> 2023年5月より、ヘルスケア特化型ベンチャーキャピタルであるFast Track Initiativeに参画。メドテック、ヘルステック領域の投資を担当。
                  <br />
                  日本専門医機構認定内科専門医、日本医師会認定産業医。
                  <br />
                  厚生労働省医療系ベンチャー・トータルサポート事業 非常勤サポーター。米国ミシガン大学Ross School of Business修了。MBA。
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div className="flex">
              <img src={`${process.env.PUBLIC_URL}/assets/demoday2024/images/tsuda.jpg`} alt="津田 将志" />
              <div class="desc">
                <p style={{ fontWeight: "bold" }}>
                  Beyond Next Ventures 株式会社
                  <br />
                  エコシステム部 インキュベーションチーム マネージャー
                </p>
                <h4>
                  津田 将志<span>Masashi TSUDA</span>
                </h4>
                <p>
                  京都大学大学院工学研究科高分子化学専攻修了
                  <br />
                  薬剤送達技術(DDS)の研究に従事 2015年4月 NKメディコ入社。
                  <br />
                  血管内皮障害の早期検出を可能にするアカデミア発の血液検査の医療機関向け営業を担当し、社内MVPを受賞。その後、学術営業及び大学・医療機関との共同研究プロジェクトの推進を行う。
                  2019年4月に当社に参画し、研究シーズの事業化を支援する「BRAVE」やバイオスタートアップ向けシェア型ウェットラボ「Beyond BioLAB
                  TOKYO」など、研究開発型スタートアップのインキュベーションにかかわる事業を統括。
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div className="flex">
              <img src={`${process.env.PUBLIC_URL}/assets/demoday2024/images/gushima.jpg`} alt="具島 三佳" />
              <div class="desc">
                <p style={{ fontWeight: "bold" }}>QBキャピタル合同会社　シニアアソシエイト</p>
                <h4>
                  具島 三佳<span>Mika GUSHIMA</span>
                </h4>
                <ul style={{ padding: "0.4em" }}>
                  <li>博士号取得後、独立行政法人医薬品医療機器総合機構(PMDA)にて医薬品の審査員として勤務。</li>
                  <li>2020年9月からQBキャピタルに参画し、バイオ・ヘルスケア分野の投資を担当。</li>
                  <li>九州大学医学系学府医学専攻博士課程修了(医学博士)。</li>
                  <li>九州大学経済学府産業マネジメント専攻修了(MBA)。</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SSpeakers>
  );
};
export default Speakers;
const SSpeakers = styled.section`
  .commentator {
    ul li {
      list-style-type: disc;
    }
    h3 {
      font-size: 3.2rem;
      color: ${v.primaryColor24};
      margin-bottom: 0.3em;
    }
    .item {
      border-bottom: 1px solid ${v.primaryColor24};
      padding-bottom: 30px;
      margin-bottom: 30px;
      .flex {
        flex-direction: row;
        align-items: flex-start;
        img {
          width: 200px;
          @media ${d.maxTab} {
            width: 100%;
            margin-bottom: 10px;
          }
        }
        .desc {
          width: calc(100% - 200px - 40px);
          @media ${d.maxTab} {
            width: 100%;
          }
          h4 {
            color: ${v.primaryColor24};
            font-size: 2rem;
            span {
              font-size: 0.7em;
              display: inline-block;
              margin-left: 1em;
            }
          }
        }
      }
    }
  }

  position: relative;
  margin: 0;
  padding: 6vw 4vw;
  /* background-color: #3f7ec3; */
  /* background-image: url(${bg});
  background-size: cover;
  background-position: center center; */
  @media ${d.maxSp} {
    padding: 18vw 8vw;
  }

  img.circle-03 {
    position: absolute;
    bottom: -6vw;
    right: -15vw;
    z-index: 2;
    width: 36vw;
    height: auto;
    max-width: 660px;
    @media ${d.maxTab} {
      bottom: -3vw;
      width: 50vw;
    }
  }

  .col-left {
    z-index: 2;
    width: 12vw;
    @media ${d.maxTab} {
      width: 100%;
    }
    .title-en {
      font-family: "Roboto";
      color: ${v.primaryColor24};
      font-size: 12vw;
      font-weight: bold;
      letter-spacing: -0.03em;
      transform: rotate(90deg);
      transform-origin: 50% 50%;
      line-height: 12vw;
      @media ${d.maxTab} {
        transform: none;
      }
    }
  }
  .col-right {
    z-index: 2;
    width: calc(100vw - 12vw - 14vw);
    @media ${d.maxTab} {
      width: 100%;
    }
    .title-box {
      margin-bottom: 30px;
    }
    h2 {
      color: ${v.primaryColor24};
      font-size: 3.2rem;
      padding: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
    .speaker-list {
      .item {
        margin-bottom: 40px;
        text-align: center;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 12px;
          left: 12px;
          width: 100%;
          height: 100%;
          background-image: url(${overlay});
        }

        div.flex {
          flex-direction: column;
          position: relative;
          z-index: 1;

          a.image-box {
            position: relative;
            display: inline-flex;
            width: 100%;
            aspect-ratio: 1 / 1;

            :before {
              content: "研究詳細";
              position: absolute;
              z-index: 2;
              left: 0;
              bottom: 0;
              width: auto;
              height: auto;
              padding: 1em 1.5em 1em 2em;
              background-color: #fff;
              color: #111;
              font-size: 1.4rem;
              font-weight: bold;
            }
            :after {
              content: "";
              position: absolute;
              z-index: 3;
              bottom: calc(1em + 4px);
              left: 1em;
              width: 6px;
              height: 6px;
              border-top: solid 1px #111;
              border-right: solid 1px #111;
              transform: rotate(45deg);
            }
            img {
              position: relative;
              z-index: 1;
            }
          }

          .desc {
            flex-grow: 2;
            background-color: ${v.primaryColor24};
            text-align: left;
            > h4 {
              background-color: #00595e;
              padding: 1rem 1.8rem;
              color: #fff;
              font-size: 1.6rem;
              text-align: left;
              font-weight: normal;
              line-height: 1.55em;
            }
            .inner {
              padding: 1rem 1.8rem;
              h3 {
                color: #fff;
                padding: 0;
                .author-name {
                  display: block;
                  margin-right: 1em;
                }
                .author-en-name {
                  display: block;
                  font-size: 1.2rem;
                  font-weight: normal;
                }
              }
              h4 {
                line-height: 1.5em;
                font-size: 1.5rem;
                color: #fff;
                padding-bottom: 0;
                font-weight: normal;
                span {
                  display: inline-block;
                  margin-right: 0.5em;
                }
              }
              hr {
                background-color: ${v.secondaryColor22};
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .col-4 {
    width: 29%;
    @media ${d.maxTab} {
      width: 90%;
      margin: 0 auto;
    }
  }
`;
