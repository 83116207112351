/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 FIELD_2025.gltf --keepnames --keepgroups --shadows --meta 
*/

import React, { useState, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

import { useSpring, a } from "@react-spring/three";
import * as easings from "d3-ease";

import gsap from "gsap";

import { useGesture } from "react-use-gesture";
import { v } from "../../CssVariables";

import Balloon1 from "./Balloon/2025/Balloon1";
import Balloon2 from "./Balloon/2025/Balloon2";
import Balloon3 from "./Balloon/2025/Balloon3";
import Balloon4 from "./Balloon/2025/Balloon4";
import Balloon5 from "./Balloon/2025/Balloon5";
import Balloon6 from "./Balloon/2025/Balloon6";
import BalloonDemoday from "./Balloon/2025/BalloonDemoday";

export default function Model(props) {
  const group = useRef();
  const project1 = useRef();
  const project2 = useRef();
  const project3 = useRef();
  const project4 = useRef();
  const project5 = useRef();
  const project6 = useRef();
  const demoday = useRef();

  const { nodes, materials, animations } = useGLTF("/assets/models/FIELD_2025.gltf");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions.project1Anim.play();
    actions.project2Anim.play();
    actions.project3Action.play();
    actions.project4Action.play();
    actions.project5Anim.play();
    actions.project6Anim.play();
  });

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);
  const [hoverDemoday, setHoverDemoday] = useState(false);
  /**
   * クリックによるアニメーションと遷移
   * @param {*} item
   * @param {*} clickEventItem
   */
  // setLookAt(positionX, positionY, positionZ, targetX, targetY, targetZ, enableTransition)
  const lookAtFunc = (item) => {
    console.log(item.current.position.x, item.current.position.y, item.current.position.z);

    props.cameraControlRef.current?.setLookAt(
      item.current.position.x + 2,
      item.current.position.y + 15,
      item.current.position.z + 20,
      item.current.position.x + 2,
      item.current.position.y - 7,
      item.current.position.z - 5,
      true
    );
  };

  const operateProject1 = useGesture({
    onPointerOver: () => setHover1(true),
    onPointerOut: () => setHover1(false),
    onClick: () => {
      lookAtFunc(project1);
      setTimeout(() => {
        props.onClickProject1();
      }, 1000);
    },
  });
  const operateProject2 = useGesture({
    onPointerOver: () => setHover2(true),
    onPointerOut: () => setHover2(false),
    onClick: () => {
      lookAtFunc(project2);
      setTimeout(() => {
        props.onClickProject2();
      }, 1000);
    },
  });
  const operateProject3 = useGesture({
    onPointerOver: () => setHover3(true),
    onPointerOut: () => setHover3(false),
    onClick: () => {
      lookAtFunc(project3);
      setTimeout(() => {
        props.onClickProject3();
      }, 1000);
    },
  });
  const operateProject4 = useGesture({
    onPointerOver: () => setHover4(true),
    onPointerOut: () => setHover4(false),
    onClick: () => {
      lookAtFunc(project4);
      setTimeout(() => {
        props.onClickProject4();
      }, 1000);
    },
  });
  const operateProject5 = useGesture({
    onPointerOver: () => setHover5(true),
    onPointerOut: () => setHover5(false),
    onClick: () => {
      lookAtFunc(project5);
      setTimeout(() => {
        props.onClickProject5();
      }, 1000);
    },
  });
  const operateProject6 = useGesture({
    onPointerOver: () => setHover6(true),
    onPointerOut: () => setHover6(false),
    onClick: () => {
      lookAtFunc(project6);
      setTimeout(() => {
        props.onClickProject6();
      }, 1000);
    },
  });

  const operateDemoday = useGesture({
    onPointerOver: () => setHoverDemoday(true),
    onPointerOut: () => setHoverDemoday(false),
    onClick: () => {
      lookAtFunc(demoday);
      setTimeout(() => {
        props.onClickDemoday();
      }, 1000);
    },
  });

  const config = { config: { duration: 500, easing: easings.easeBackOut } };

  const { scale1 } = useSpring({ scale1: hover1 ? 1.15 : 1, config });
  const { scale2 } = useSpring({ scale2: hover2 ? 1.15 : 1, config });
  const { scale3 } = useSpring({ scale3: hover3 ? 1.15 : 1, config });
  const { scale4 } = useSpring({ scale4: hover4 ? 1.15 : 1, config });
  const { scale5 } = useSpring({ scale5: hover5 ? 1.15 : 1, config });
  const { scale6 } = useSpring({ scale6: hover6 ? 1.15 : 1, config });

  /**
   * ref
   */
  const field2025Ref = useRef();

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group ref={field2025Ref} name="FIELD_2025" position={[-0.011, 6.2, 0.062]} rotation={[0, 0, 0]} scale={1} userData={{ name: "FIELD_2025" }}>
          <group name="COMMON" position={[1.065, -6.179, 0.656]} userData={{ name: "COMMON" }}>
            {/* demoday */}
            <group ref={demoday} name="theater" position={[-29.388, 6.179, -13.227]} userData={{ name: "theater" }} {...operateDemoday()}>
              <mesh
                name="screen"
                castShadow
                receiveShadow
                geometry={nodes.screen.geometry}
                material={materials.screen_2025}
                position={[29.388, -6.179, 13.227]}
                userData={{ name: "screen" }}
              />
              <mesh
                name="theater_base"
                castShadow
                receiveShadow
                geometry={nodes.theater_base.geometry}
                material={materials.base_color_2025}
                position={[29.388, -6.179, 13.227]}
                userData={{ name: "theater_base" }}>
                <meshStandardMaterial color={v.primaryColor24} />
              </mesh>
              <BalloonDemoday hoverDemoday={hoverDemoday} />
            </group>
            <mesh
              name="tree"
              castShadow
              receiveShadow
              geometry={nodes.tree.geometry}
              material={materials.base_color_2025}
              position={[-17.011, 2.3, 8.855]}
              userData={{ name: "tree" }}>
              <meshStandardMaterial color={v.primaryColor24} />
            </mesh>
          </group>
          <group name="LIFE_SCIENCE" position={[1.065, -6.179, 0.656]} userData={{ name: "LIFE_SCIENCE" }}>
            <group name="stage_lifeScience" position={[-10.5, 0.25, 12.517]} userData={{ name: "stage_lifeScience" }}>
              <mesh
                name="side"
                castShadow
                receiveShadow
                geometry={nodes.side.geometry}
                material={materials.base_color_2025}
                position={[10.5, -0.25, -12.517]}
                userData={{ name: "side" }}>
                <meshStandardMaterial color={v.primaryColor24} />
              </mesh>
              <mesh
                name="top"
                castShadow
                receiveShadow
                geometry={nodes.top.geometry}
                material={materials.White1}
                position={[10.5, -0.25, -12.517]}
                userData={{ name: "top" }}
              />
            </group>
            <a.mesh
              ref={project1}
              name="project1"
              castShadow
              receiveShadow
              geometry={nodes.project1.geometry}
              material={materials.project_2025_01}
              position={[-20.16, 3.5, 17.937]}
              userData={{ name: "project1" }}
              {...operateProject1()}
              scale={scale1}>
              <Balloon1 hover1={hover1} />
            </a.mesh>
            <a.mesh
              ref={project3}
              name="project3"
              castShadow
              receiveShadow
              geometry={nodes.project3.geometry}
              material={materials.project_2025_03}
              position={[-10.591, 3.5, 7.767]}
              userData={{ name: "project3" }}
              {...operateProject3()}
              scale={scale3}>
              <Balloon3 hover3={hover3} />
            </a.mesh>
            <a.mesh
              ref={project4}
              name="project4"
              castShadow
              receiveShadow
              geometry={nodes.project4.geometry}
              material={materials.project_2025_04}
              position={[-1.318, 3.5, 19.75]}
              userData={{ name: "project4" }}
              {...operateProject4()}
              scale={scale4}>
              <Balloon4 hover4={hover4} />
            </a.mesh>
            <mesh
              name="text_lifeScience"
              castShadow
              receiveShadow
              geometry={nodes.text_lifeScience.geometry}
              material={materials.base_color_2025}
              position={[-21.346, 0.55, 24.193]}
              userData={{ name: "text_lifeScience" }}>
              <meshStandardMaterial color={v.primaryColor24} />
            </mesh>
          </group>
          <group name="MANUFACTURING" position={[1.065, -6.179, 0.656]} userData={{ name: "MANUFACTURING" }}>
            <group name="stage_manufacturing" position={[12.684, 0.25, -13.83]} userData={{ name: "stage_manufacturing" }}>
              <mesh
                name="side001"
                castShadow
                receiveShadow
                geometry={nodes.side001.geometry}
                material={materials.base_color_2025}
                position={[-12.684, -0.25, 13.83]}
                userData={{ name: "side.001" }}>
                <meshStandardMaterial color={v.primaryColor24} />
              </mesh>
              <mesh
                name="top001"
                castShadow
                receiveShadow
                geometry={nodes.top001.geometry}
                material={materials.White1}
                position={[-12.684, -0.25, 13.83]}
                userData={{ name: "top.001" }}
              />
            </group>
            <a.mesh
              ref={project2}
              name="project2"
              castShadow
              receiveShadow
              geometry={nodes.project2.geometry}
              material={materials.project_2025_02}
              position={[3.131, 3.5, -21.614]}
              userData={{ name: "project2" }}
              {...operateProject2()}
              scale={scale2}>
              <Balloon2 hover2={hover2} />
            </a.mesh>
            <a.mesh
              ref={project5}
              name="project5"
              castShadow
              receiveShadow
              geometry={nodes.project5.geometry}
              material={materials.project_2025_05}
              position={[12.604, 3.5, -8.141]}
              userData={{ name: "project5" }}
              {...operateProject5()}
              scale={scale5}>
              <Balloon5 hover5={hover5} />
            </a.mesh>
            <a.mesh
              ref={project6}
              name="project6"
              castShadow
              receiveShadow
              geometry={nodes.project6.geometry}
              material={materials.project_2025_06}
              position={[21.55, 3.5, -19.686]}
              userData={{ name: "project6" }}
              {...operateProject6()}
              scale={scale6}>
              <Balloon6 hover6={hover6} />
            </a.mesh>
            <mesh
              name="text_manufacturing"
              castShadow
              receiveShadow
              geometry={nodes.text_manufacturing.geometry}
              material={materials.base_color_2025}
              position={[23.504, 0.55, -2.158]}
              userData={{ name: "text_manufacturing" }}>
              <meshStandardMaterial color={v.primaryColor24} />
            </mesh>
          </group>
          <group name="YEARPLATE" userData={{ name: "YEARPLATE" }}>
            <mesh
              name="year_2025"
              castShadow
              receiveShadow
              geometry={nodes.year_2025.geometry}
              material={materials.White1}
              position={[41.064, -6.179, 7.495]}
              userData={{ name: "year_2025" }}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/FIELD_2025.gltf");
