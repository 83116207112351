import React, { useState, useRef } from "react";

import { useGLTF } from "@react-three/drei";

import { useGesture } from "react-use-gesture";
import { v } from "../../CssVariables";

import BalloonUniversityKobe from "./Balloon/BalloonUniversityKobe";
import BalloonUniversityOit from "./Balloon/BalloonUniversityOit";

export default function Model(props) {
  const kobeCampus = useRef();
  const oitCampus = useRef();

  const { nodes, materials } = useGLTF("/assets/models/COMMON_TO_ALL_YEARS.glb");

  const [hoverUniversityKobe, setHoverUniversityKobe] = useState(false);
  const [hoverUniversityOit, setHoverUniversityOit] = useState(false);

  const operateUniversityKobe = useGesture({
    onPointerOver: () => setHoverUniversityKobe(true),
    onPointerOut: () => setHoverUniversityKobe(false),
    onClick: () => {
      props.cameraControlRef.current?.setLookAt(
        kobeCampus.current.position.x - 49.5,
        kobeCampus.current.position.z + 16,
        kobeCampus.current.position.y - 15,
        kobeCampus.current.position.x - 49.5,
        kobeCampus.current.position.z + 3,
        kobeCampus.current.position.y - 42,
        true
      );
      setTimeout(() => {
        props.onClickUniversityKobe();
      }, 1000);
    },
  });
  const operateUniversityOit = useGesture({
    onPointerOver: () => setHoverUniversityOit(true),
    onPointerOut: () => setHoverUniversityOit(false),
    onClick: () => {
      props.cameraControlRef.current?.setLookAt(
        kobeCampus.current.position.x + 20,
        kobeCampus.current.position.z + 16,
        kobeCampus.current.position.y - 16,
        kobeCampus.current.position.x + 20,
        kobeCampus.current.position.z + 5,
        kobeCampus.current.position.y - 42,
        true
      );
      setTimeout(() => {
        props.onClickUniversityOit();
      }, 1000);
    },
  });

  return (
    <group {...props} dispose={null} scale={100}>
      <group name="Scene">
        <mesh name="PLANE_FIELD" geometry={nodes.PLANE_FIELD.geometry} material={materials.plane_field} position={[0, -0.01, -0.5]} receiveShadow>
          <meshStandardMaterial
            map={materials.plane_field.map}
            color={
              props.year === "2025"
                ? v.primaryColor24
                : props.year === "2024"
                ? v.primaryColor23
                : props.year === "2023"
                ? v.primaryColor22
                : props.year === "2022"
                ? v.primaryColor21
                : props.year === "2021"
                ? v.primaryColor20
                : null
            }
          />
        </mesh>

        <mesh name="field" geometry={nodes.field.geometry} material={materials.field} position={[0.31, -0.005, -0.474]} receiveShadow>
          <meshStandardMaterial
            map={materials.field.map}
            color={
              props.year === "2025"
                ? v.primaryColor24
                : props.year === "2024"
                ? v.primaryColor23
                : props.year === "2023"
                ? v.primaryColor22
                : props.year === "2022"
                ? v.primaryColor21
                : props.year === "2021"
                ? v.primaryColor20
                : null
            }
          />
        </mesh>

        <mesh name="text_main" geometry={nodes.text_main.geometry} material={materials["White1.001"]} position={[0.246, 0.001, 0.085]} />

        <mesh name="tree" geometry={nodes.tree.geometry} material={materials.tree} position={[0.075, 0.013, -0.006]}>
          <meshStandardMaterial
            map={materials.tree.map}
            color={
              props.year === "2025"
                ? v.primaryColor24
                : props.year === "2024"
                ? v.primaryColor23
                : props.year === "2023"
                ? v.primaryColor22
                : props.year === "2022"
                ? v.primaryColor21
                : props.year === "2021"
                ? v.primaryColor20
                : null
            }
          />
        </mesh>

        <group name="UNIVERSITY">
          <mesh
            ref={kobeCampus}
            name="kobe_university001"
            geometry={nodes.kobe_university001.geometry}
            material={materials.campus_kobe}
            position={[-0.501, 0.032, -0.514]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}
            {...operateUniversityKobe()}>
            <meshStandardMaterial map={materials.campus_kobe.map} color={"#aaaaaa"} />
          </mesh>

          <mesh
            ref={oitCampus}
            name="oit"
            geometry={nodes.oit.geometry}
            material={materials.campus_oit}
            position={[0.188, 0.078, -0.463]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}
            {...operateUniversityOit()}>
            <meshStandardMaterial map={materials.campus_oit.map} color={"#aaaaaa"} />
          </mesh>

          <mesh
            name="text_kobe_university001"
            geometry={nodes.text_kobe_university001.geometry}
            material={materials["White1.001"]}
            position={[-0.493, -0.01, -0.35]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}>
            <BalloonUniversityKobe hoverUniversityKobe={hoverUniversityKobe} />
          </mesh>

          <mesh
            name="text_oit"
            geometry={nodes.text_oit.geometry}
            material={materials["White1.001"]}
            position={[0.208, -0.01, -0.404]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}>
            <BalloonUniversityOit hoverUniversityOit={hoverUniversityOit} />
          </mesh>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/COMMON_TO_ALL_YEARS.glb");
