import styled from "styled-components";
import { d, v } from "../../../CssVariables";
import { IoChevronForwardSharp } from "react-icons/io5";
import bg from "../images/bg-overview.jpg";

const Overview = () => {
  return (
    <SOverview id="overview">
      <div className="title-box">
        <div className="title-en-large title-en center">OVERVIEW</div>
        <h2 className="center">イベント概要</h2>
      </div>
      <div className="desc">
        <dl>
          <dt>開催日時</dt>
          <dd>2025年2月19日(水) 13:30〜16:00</dd>
          <dt>開催形式</dt>
          <dd>YouTubeライブによるオンライン配信</dd>
          <dt>開催場所</dt>
          <dd>
            <ul>
              <li>
                神戸大学 六甲台第2キャンパス 産官学連携本部1階 ダイセルOIホール
                <a className="to_googlemap flex" href="https://www.org.kobe-u.ac.jp/idx-hq/dx/access_OIHall.html">
                  <IoChevronForwardSharp />
                  会場アクセス
                </a>
              </li>
              <li>オンライン配信(ブラウザ上にて視聴)</li>
            </ul>
          </dd>
          <dt>費用</dt>
          <dd>無料</dd>
          <dt>参加申込</dt>
          <dd>
            <a
              href="https://forms.office.com/pages/responsepage.aspx?id=BgaLzUoz5ke7n9VwcRig6lenyvQ2A3xNsX9-2P_6Zo9UOFRBQVBHTDVPTFUyNVNXRUNaSVZCNFVYTy4u&route=shorturl"
              style={{ textDecoration: "underline" }}>
              参加申込フォーム
            </a>
            よりご登録ください
            <br />
            {/* 申込締切: 2023年3月4日(月) */}
          </dd>
          <dt>主催</dt>
          <dd>神戸大学、大阪工業大学</dd>
          <dt>後援</dt>
          <dd>
            <span>公益社団法人関西経済連合会、</span>
            <span>一般社団法人関西経済同友会、</span>
            <span>大阪商工会議所、</span>
            <span>神戸商工会議所、</span>
            <span>公益財団法人大阪産業局、</span>
            <span>神戸市、</span>
            <span>株式会社三井住友銀行、</span>
            <span>大阪信用金庫、</span>
            <span>関西イノベーションイニシアティブ(KSII)、</span>
            <span>関西スタートアップアカデミア・コアリション(KSAC)、</span>
            <span>大阪イノベーションハブ(OIH)、</span>
            <span>Xport、</span>
            <span>アンカー神戸</span>
          </dd>
        </dl>
      </div>
    </SOverview>
  );
};
export default Overview;

const SOverview = styled.section`
  margin: 0;
  padding: 8vw 6%;
  background-color: #fff;
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }

  .title-box {
    .title-en {
      color: #fff;
    }
    h2 {
      color: #fff;
      font-size: 3.2rem;
      padding-bottom: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
  }

  .desc {
    width: 900px;
    margin: 0 auto;
    padding: 0 30px;
    @media ${d.maxTab} {
      width: 100%;
      padding: 0;
    }
  }

  a.to_googlemap.flex {
    display: inline-flex !important;
    flex-direction: row;
    align-items: center !important;
    font-size: 1.3rem;
    background: ${v.primaryColor24};
    color: #fff;
    display: inline-block;
    margin-left: 1em;
    @media ${d.maxTab} {
      margin-left: 0;
    }
    padding: 0 1em;
    border-radius: 1000px;
  }

  dl {
    dt {
      padding: 0.75em 20px;
      background-color: rgba(255, 255, 255, 0.8);
      @media ${d.maxTab} {
        background-color: #fff;
        padding: 0.75em 20px 0;
      }
    }
    dd {
      padding: 0.75em 20px;
      background-color: rgba(255, 255, 255, 0.8);
      @media ${d.maxTab} {
        background-color: #fff;
        padding: 0 20px 0.75em;
      }
    }
    span {
      display: inline-block;
      // padding-right: 0.2em;
    }
  }
`;
