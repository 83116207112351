import { useEffect } from "react";
import BalloonBase from "../BalloonBase2025";

const Balloon3 = (props) => {
  const { hover3 } = props;
  useEffect(() => {
    document.body.style.cursor = hover3 ? "pointer" : "default";
  }, [hover3]);

  return <BalloonBase whichHover={hover3}>安全で地球に優しいKobe培養ビーフの生産技術の構築と培養装置の開発</BalloonBase>;
};
export default Balloon3;
