import styled from "styled-components";
import { useMediaQuery } from "../../UseMediaQuery";
import useMeasure from "react-use-measure";
import ReactPlayer from "react-player/youtube";
import { v } from "../../CssVariables";

const ProjectDetail = (props) => {
  const { thisProject } = props;
  const mq = useMediaQuery();
  const [ref, videoWrapperSize] = useMeasure();

  return (
    <SProjectDescription>
      {thisProject.video !== null && (
        <section id="projectMovie">
          <div className="width-max">
            <div
              width="zw100%"
              ref={ref}
              className="video-wrapper"
              style={(mq.isPC && { height: (videoWrapperSize.width / 5) * 1.666 }) || (mq.isSP && { height: (videoWrapperSize.width / 16) * 9 })}>
              <ReactPlayer
                width="100%"
                height="100%"
                url={thisProject.video.url}
                light={thisProject.video.thumbnail} // サムネイル
                // onPause={() => console.log('onPause')}
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                      controls: 1,
                      modestbranding: 1,
                      rel: 0, // 関連動画の非表示
                      showinfo: 0, // サポート終了のため効かない
                    },
                  },
                }}
              />
            </div>
          </div>
        </section>
      )}

      {thisProject.overviewText === null && thisProject.overviewImage === null ? null : (
        <>
          <section id="overview">
            <div className="flex">
              <div className={thisProject.overviewImage === null ? "col-max" : "col"}>
                <h3>プロジェクトの概要</h3>
                {thisProject.overviewText !== null && thisProject.overviewText.map((elem, i) => <div key={i}>{elem}</div>)}
              </div>
              {thisProject.overviewImage !== null && (
                <div className="col">
                  <img src={thisProject.overviewImage} alt="" />
                </div>
              )}
            </div>
          </section>
          <div className="width-max-border"></div>
        </>
      )}

      {thisProject.buisinessModelText === null && thisProject.buisinessModelImage === null ? null : (
        <>
          <section id="buisinessModel">
            <div className="flex">
              <div className={thisProject.buisinessModelImage === null ? "col-max" : "col"}>
                <h3>ビジネスモデル</h3>
                {thisProject.buisinessModelText !== null && thisProject.buisinessModelText.map((elem, i) => <div key={i}>{elem}</div>)}
              </div>
              {thisProject.buisinessModelImage !== null && (
                <div className="col">
                  <img src={thisProject.buisinessModelImage} alt="" />
                </div>
              )}
            </div>
          </section>
          <div className="width-max-border"></div>
        </>
      )}

      {thisProject.activityPlanText === null && thisProject.activityPlanImage === null ? null : (
        <>
          <section id="activityPlan">
            <div className="flex">
              <div className={thisProject.activityPlanImage === null ? "col-max" : "col"}>
                <h3>活動計画</h3>
                {thisProject.activityPlanText !== null && thisProject.activityPlanText.map((elem, i) => <div key={i}>{elem}</div>)}
              </div>
              {thisProject.activityPlanImage !== null && (
                <div className="col">
                  <img src={thisProject.activityPlanImage} alt="" />
                </div>
              )}
            </div>
          </section>
          <div className="width-max-border"></div>
        </>
      )}

      <section id="resercher">
        <div className="content-over-box">
          <div className="content-over-inner">
            <div className="flex">
              <div className="col flex">
                <h3>研究代表者</h3>
                <p className="author-office">
                  <span>{thisProject.authorUniversity}</span>
                  <span>{thisProject.authorAffiliation}</span>
                </p>
                <p className="author-name">
                  {thisProject.authorName} {thisProject.authorPosition}
                </p>
              </div>
              {thisProject.portrait && (
                <div className="col flex">
                  <img src={thisProject.portrait} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="width-max-border"></div>
    </SProjectDescription>
  );
};
export default ProjectDetail;

const SProjectDescription = styled.div`
  h4 {
    color: ${v.textColor};
  }
  ul > li {
    list-style-type: disc;
    margin-left: 1em;
  }
  ol > li {
    text-indent: -1.2em;
    padding-left: 1.2em;
  }
  section#projectMovie {
    margin: 0;
    .react-player__shadow {
      background: none !important;
      .react-player__play-icon {
        border-width: 32px 0px 32px 52px !important;
      }
    }
  }

  section#resercher {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #ddd;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #eee;
    .flex {
      .col.flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 1vw;

        h3 {
          text-align: center;
        }
        p {
          padding-top: 0;
          padding-bottom: 0;
        }
        p.author-office {
          text-align: center;
          span {
            display: inline-block;
            padding-right: 0.5em;
          }
        }
        p.author-name {
          text-align: center;
        }
        img {
          max-width: 220px;
        }
      }
    }
  }

  .content-over-box {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    background: #eee;
  }
`;
