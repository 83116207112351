import { useEffect } from "react";
import BalloonBase from "../BalloonBase2025";

const Balloon1 = (props) => {
  const { hover1 } = props;
  useEffect(() => {
    document.body.style.cursor = hover1 ? "pointer" : "default";
  }, [hover1]);

  return <BalloonBase whichHover={hover1}>がんの生体内ライブイメージング医療を実現する顕微内視鏡技術の機能高度化</BalloonBase>;
};
export default Balloon1;
