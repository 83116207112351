import React, { createContext, useState } from "react";

export const YearContext = createContext({});

export const YearProvider = (props) => {
  const { children } = props;

  const [year, setYear] = useState("2025");

  return <YearContext.Provider value={{ year, setYear }}>{children}</YearContext.Provider>;
};
