import React, { useContext } from "react";
import styled from "styled-components";

import { YearContext } from "../../providers/YearProvider";
import { AnnounceContext } from "../../providers/AnnounceProvider";
import Button from "../atoms/Button";
import { IoArrowForwardSharp } from "react-icons/io5";
import { d, v } from "../../CssVariables";

const ArchiveLinks = () => {
  const { year, setYear } = useContext(YearContext);
  const { announce } = useContext(AnnounceContext);

  const changeYear25 = () => {
    setYear("2025");
  };
  const changeYear24 = () => {
    setYear("2024");
  };
  const changeYear23 = () => {
    setYear("2023");
  };
  const changeYear22 = () => {
    setYear("2022");
  };
  const changeYear21 = () => {
    setYear("2021");
  };

  return (
    <SArchiveLinks className="archive-links flex" show={announce}>
      <p>ARCHIVE</p>
      <div className="year-box flex">
        {year === "2025" && (
          <>
            <button onClick={changeYear24}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2023年
            </button>
            <button onClick={changeYear23}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2022年
            </button>
            <button onClick={changeYear22}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2021年
            </button>
            <button onClick={changeYear21}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2020年
            </button>
          </>
        )}

        {year === "2024" && (
          <>
            <button onClick={changeYear25}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2024年
            </button>
            <button onClick={changeYear23}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2022年
            </button>
            <button onClick={changeYear22}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2021年
            </button>
            <button onClick={changeYear21}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2020年
            </button>
          </>
        )}
        {year === "2023" && (
          <>
            <button onClick={changeYear25}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2024年
            </button>
            <button onClick={changeYear24}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2023年
            </button>
            <button onClick={changeYear22}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2021年
            </button>
            <button onClick={changeYear21}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2020年
            </button>
          </>
        )}

        {year === "2022" && (
          <>
            <button onClick={changeYear25}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2024年
            </button>
            <button onClick={changeYear24}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2023年
            </button>
            <button onClick={changeYear23}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2022年
            </button>
            <button onClick={changeYear21}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2020年
            </button>
          </>
        )}

        {year === "2021" && (
          <>
            <button onClick={changeYear25}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2024年
            </button>
            <button onClick={changeYear24}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2023年
            </button>
            <button onClick={changeYear23}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2022年
            </button>
            <button onClick={changeYear22}>
              <span className="arrow">
                <IoArrowForwardSharp />
              </span>
              2021年
            </button>
          </>
        )}
      </div>
    </SArchiveLinks>
  );
};
export default ArchiveLinks;

const SArchiveLinks = styled.div`
  opacity: ${({ show }) => (show ? 0 : 1)};
  transform: ${({ show }) => (show ? "translateY(10px)" : "translateY(0px)")};
  transition: all 0.5s 0.5s;
  flex-direction: column !important;
  @media ${d.maxTab} {
    align-items: center;
  }
  > p {
    font-weight: bold;
    color: #fff;
    @media ${d.maxSp} {
      display: none;
    }
  }
  .year-box {
    flex-direction: column;
    width: 100%;
    @media ${d.maxTab} {
      padding: 0 10px;
      flex-direction: row;
    }
  }
  button {
    display: inline-block;
    background-color: #fff;
    color: ${v.textColor};
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 6px;
    padding: 0.8rem 3rem;
    border-radius: 2px;
    margin-bottom: 10px;
    width: 100%;
    font-weight: bold;
    &:last-child {
      margin-bottom: 0;
      font-weight: bold;
    }
    @media ${d.maxTab} {
      padding: 0.8rem 0.2rem;
      width: 23%;
      font-size: 0.8em;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
  span {
    vertical-align: middle;
    display: inline-block;
    padding: 0 0.2em;
  }
`;
