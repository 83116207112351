import { useEffect } from "react";
import BalloonBase from "../BalloonBase2025";

const Balloon6 = (props) => {
  const { hover6 } = props;
  useEffect(() => {
    document.body.style.cursor = hover6 ? "pointer" : "default";
  }, [hover6]);

  return <BalloonBase whichHover={hover6}>広視域ライトフィールドカメラの高精細化による表面欠陥検出システムの開発</BalloonBase>;
};
export default Balloon6;
