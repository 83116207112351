/** ----------------------------------
 * variable settings
 * ---------------------------------- */

// variables
export const v = {
  primaryColor24: "#088389",
  primaryColor23: "#155eb9",
  primaryColor22: "#E1B30C",
  primaryColor21: "#c10048",
  primaryColor20: "#037858",
  secondaryColor24: "#111111",
  secondaryColor23: "#111111",
  secondaryColor22: "#111111",
  secondaryColor21: "#2e2bbf",
  tertiaryColor: "#f8f8f8",
  textColor: "#333",
  headerHeightSp: "66px",
  headerHeightPc: "70px",
};

/*  device sizes (media query) */
// sizes
const s = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  minTablet: "768px",
  minLaptop: "1024px",
  minLaptopL: "1440px",
  minDesktop: "2560px",
  maxSp: "599px",
  maxTab: "1024px",
};
// devices
export const d = {
  mobileS: `(min-width: ${s.mobileS})`,
  mobileM: `(min-width: ${s.mobileM})`,
  mobileL: `(min-width: ${s.mobileL})`,
  minTab: `(min-width: ${s.minTablet})`,
  minLaptop: `(min-width: ${s.minLaptop})`,
  minLaptopL: `(min-width: ${s.minLaptopL})`,
  minDesktop: `(min-width: ${s.minDesktop})`,
  maxSp: `(max-width: ${s.maxSp})`,
  maxTab: `(max-width: ${s.maxTab})`,
};
