import styled from "styled-components";
import MainTitle from "../atoms/MainTitle";
import { v, d } from "../../CssVariables";

const ProjectHero = (props) => {
  const { thisProject } = props;
  return (
    <SProjectHero id="heroSection">
      <div className="width-max">
        <div className="project-meta-box">
          <p className="number">
            <span>PROJECT No.{thisProject.id}</span>
          </p>
          <p className="category">
            <span>{thisProject.category}</span>
          </p>
          <p className="year">
            <span>採択年度 - {thisProject.adoptionYear}</span>
          </p>
        </div>
      </div>

      <div className="width-max">
        <div className="flex">
          <div className="project-title-box">
            <MainTitle>{thisProject.title}</MainTitle>
            {thisProject.subtitle === null ? null : <h2>{thisProject.subtitle}</h2>}
          </div>
          <div className="author-info-box">
            <h4 className="author-office">
              <span>{thisProject.authorUniversity}</span>
              <span>{thisProject.authorAffiliation}</span>
            </h4>
            <h3 className="author-name">
              <span className="name">{thisProject.authorName}</span>
              <span className="position">{thisProject.authorPosition}</span>
            </h3>
          </div>
        </div>
      </div>
    </SProjectHero>
  );
};
export default ProjectHero;

const SProjectHero = styled.div`
  width: 100%;
  padding-top: ${v.headerHeightPc};
  @media ${d.maxSp} {
    padding-top: ${v.headerHeightSp};
  }
  background-color: ${v.primaryColor24};

  .project-meta-box {
    display: flex;
    width: 100%;
    padding: 10px 5% 10px 10%;
    background-color: #fff;
    border-bottom: 1px solid ${v.textColor};
    flex-wrap: wrap;
    @media ${d.maxSp} {
      width: 100%;
      padding: 10px 6%;
    }
    p {
      display: inline-block;
      margin-right: 2em;
      font-size: 1.4rem;
      font-weight: bold;
      color: ${v.primaryColor24};
      padding: 0;
      border-right: 1px solid ${v.primaryColor24};
      padding-right: 2em;
      @media ${d.maxSp} {
        font-size: 1.4rem;
        padding-right: 1em;
      }
      &:last-child {
        border-right: none;
      }
      span {
        display: inline-block;
      }
      &.year {
        @media ${d.maxSp} {
          margin-top: 0.5em;
        }
        span {
          padding-right: 2em;
          @media ${d.maxSp} {
            padding-right: 1em;
          }
        }
      }
    }
  }
  .project-title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    padding: 20px 3% 20px 10%;
    background-color: #fff;
    @media ${d.maxSp} {
      padding: 6% 6%;
      width: 100%;
    }
    h1 {
      font-size: 2.6rem;
      padding: 0;
      color: ${v.primaryColor24};
      font-feature-settings: "palt";
      line-height: 1.3em;
      @media ${d.maxSp} {
        font-size: 2.1rem;
      }
    }
    h2 {
      font-size: 2rem;
      color: ${v.primaryColor24};
      font-feature-settings: "palt";
      @media ${d.maxSp} {
        font-size: 1.6rem;
      }
    }
  }
  .author-info-box {
    width: 30%;
    padding: 22px 5% 22px 3%;
    background-color: ${v.textColor};
    @media ${d.maxSp} {
      padding: 3% 6%;
      width: 100%;
    }
    h4 {
      font-size: 1.4rem;
      color: #fff;
      font-weight: bold;
      padding: 0;
      margin-bottom: 4px;
      @media ${d.maxSp} {
        font-size: 1.5rem;
      }
      span {
        display: inline-block;
        padding-right: 0.5em;
      }
    }
    h3 {
      font-size: 1.8rem;
      padding: 0;
      color: #fff;
      .name {
        padding-right: 0.5em;
      }
      .position {
        display: inline-block;
        font-size: 0.8em;
      }
    }
  }
`;
