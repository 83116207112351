import { useEffect, useRef } from "react";
import styled from "styled-components";
import { Html } from "@react-three/drei";
import { v } from "../../../CssVariables";

const BalloonBase = (props) => {
  const { whichHover, position, children } = props;

  const ref = useRef();

  // <Html>の親要素のpointer-eventを無効にする
  // これをしないとバルーンの後ろに隠れているものはは触れない
  useEffect(() => {
    if (ref.current) {
      ref.current.parentElement.style.pointerEvents = "none";
    }
  }, [whichHover]);

  return (
    <Html
      ref={ref}
      as={"div"} //ラッピング要素（デフォルト： 'div'）
      className="balloon"
      wrapperClass="" //ラッピング要素のclassName（デフォルト：undefined）
      prepend //キャンバスの背後にあるプロジェクトコンテンツ（デフォルト：false）
      // center // -50％/-50％のcss変換を追加します（デフォルト：false）[transform modeでは無視される]
      fullscreen="false" //左上隅に揃え、画面全体に表示します（デフォルト：false）[transform modeでは無視される]
      distanceFactor={20} //設定されている場合（デフォルト：未定義）、子はこの係数でスケーリングされ、PerspectiveCameraまでの距離/ OrthographicCameraでズームされる。
      zIndexRange={[100, 0]} // Zオーダー範囲（デフォルト= [16777271、0]）
      portal="null" //ターゲットコンテナへの参照（デフォルト=未定義）
      transform // trueの場合、matrix3d変換を適用する（デフォルト= false）
      // calculatePosition={(el: Objsect3D, camera: Camera, size: { width: number; height: number }) => number[]}transform mode]   // デフォルトのポジショニング関数をオーバーライドする。 （デフォルト=未定義）[transform modeでは無視される]
      sprite //スプライトとしてレンダリングする。transform modeのみ（デフォルト= false） ※カメラの方向に正面を向ける。
      // occlude={[ref]} // trueまたはRef <Object3D> []の場合、trueはシーン全体を遮る（デフォルト：未定義）
      // onOcclude={(visible) => null} //可視性が変更されたときのコールバック（デフォルト：未定義）
      position={position ? position : [0, 8, 0]}>
      <SPointLabel id="label" className={whichHover ? `visible` : ""}>
        <h2>{children}</h2>
      </SPointLabel>
    </Html>
  );
};
export default BalloonBase;

const SPointLabel = styled.div`
  color: ${v.textColor};
  // background-color: #ffffffE6;
  background-color: #fff;
  padding: 0.2em 2em;
  border-radius: 100vh;
  opacity: 0;
  transform: translate3d(0, 0, 0) translate(0, 20px) scale(0.9, 0.9);
  transition: all 300ms ease-in-out;
  backface-visibility: hidden;
  &* {
    pointer-events: none !important;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
    border-width: 10px 10px 0 10px;
  }
  &.visible {
    will-change: padding, transform, opacity;
    padding: 0.3em 3em;
    opacity: 1;
    transform: translate(0, 0) scale(1, 1);
    transition: all 300ms ease-in-out 150ms;
  }
`;
