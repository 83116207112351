/* eslint eqeqeq: 0 */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Head from "../../Head";
import UnderLayer from "../templates/TempUnderLayer";
import Project2024 from "../../data/Project2024";
import Pager from "../organisms/Pager2024";
import ProjectHero from "../organisms/ProjectHero2024";
import ProjectDescription from "../organisms/ProjectDescription2024";

const SingleProject = () => {
  const { projectId } = useParams();
  const thisProject = Project2024.find((element) => element.id == projectId);

  useEffect(() => {
    // const headerAnim = document.querySelector('.header-anim')
    const heroTitle = document.querySelectorAll(".hero-title");

    // header animation
    // setTimeout(() => {
    //     headerAnim.classList.add('header-anim-on')
    // }, 1000)

    // hero animation
    setTimeout(() => {
      heroTitle.forEach((item, index) => {
        setTimeout(() => {
          item.classList.add("hero-title-on");
        }, 200 * index);
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head title={thisProject.title} />
      <SSingleProject>
        <ProjectHero thisProject={thisProject} />
        <UnderLayer>
          <ProjectDescription thisProject={thisProject} />
          <Pager thisProject={thisProject} />
        </UnderLayer>
      </SSingleProject>
    </>
  );
};
export default SingleProject;
const SSingleProject = styled.div``;
