import { useEffect } from "react";
import BalloonBase from "../BalloonBase2025";

const Balloon4 = (props) => {
  const { hover4 } = props;
  useEffect(() => {
    document.body.style.cursor = hover4 ? "pointer" : "default";
  }, [hover4]);

  return <BalloonBase whichHover={hover4}>食道癌術後吻合部狭窄を解除する生体内分解性合金ステントの開発</BalloonBase>;
};
export default Balloon4;
