import styled from "styled-components";
import { d, v } from "../../../CssVariables";

const Sponsor = () => {
  return (
    <SSponsor id="sponsor">
      <div className="title-box">
        <div className="title-en-large title-en center">SPONSORS</div>
        <h2 className="center">後援</h2>
      </div>
      <div className="company-box flex">
        <div className="logo_kansai_keizai_rengokai">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_kansai_keizai_rengokai.jpg`} alt="関西経済連合会" />
        </div>
        <div className="logo_kansai_keizai_doyukai">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_kansai_keizai_doyukai.jpg`} alt="関西経済同友会" />
        </div>
        <div className="logo_osaka_shoukou_kaigisho">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_osaka_shoukou_kaigisho.png`} alt="大阪商工会議所" />
        </div>
        <div className="logo_kobe_shoukou_kaigisho">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_kobe_shokokaigisho.gif`} alt="神戸商工会議所" />
        </div>
        <div className="logo_osaka_sangyo_kyoku">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_obda.png`} alt="大阪産業局" />
        </div>
        <div className="logo_kobe_city">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_kobe_city.jpg`} alt="神戸市" />
        </div>
        <div className="logo_mitsui_sumitomo">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_mitsui_sumitomo.jpg`} alt="SMBC 三井住友銀行" />
        </div>
        <div className="logo_osaka_shinyo_kinko">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_osaka_shinyo_kinko.jpg`} alt="大阪信用金庫" />
        </div>

        <div className="logo-ksii">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_ksii.jpg`} alt="KSII" />
        </div>
        <div className="logo-ksac">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_ksac.jpg`} alt="KSAC" />
        </div>
        <div className="logo_osaka_innovation_hub">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_osaka_innovation_hub.png`} alt="OSAKA INNOVATION HUB" />
        </div>
        <div className="logo_xport">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_xport.jpg`} alt="Xport" />
        </div>
        <div className="logo_anchor">
          <img src={`${process.env.PUBLIC_URL}/assets/demoday2025/images/logo_anchor.png`} alt="ANCHOR KOBE" />
        </div>
      </div>
    </SSponsor>
  );
};
export default Sponsor;

const SSponsor = styled.section`
  margin: 0;
  background-color: #fff;
  padding: 8vw 6vw 6vw;
  @media ${d.maxSp} {
    padding: 8vw 4vw 0;
  }

  .company-box.flex {
    flex-direction: row;
    justify-content: center;
    /* max-width: 1000px; */
    margin: 0 auto;
    div {
      align-self: center;
      width: 15%;
      @media ${d.maxSp} {
        width: 40%;
      }
      margin: 0 2% 2vw 2%;
      @media ${d.maxSp} {
        margin: 0 5% 8vw 5%;
      }
      &.logo_kansai_keizai_rengokai {
        width: 14%;
        @media ${d.maxSp} {
          width: 40%;
        }
      }
      &.logo_kansai_keizai_doyukai {
        width: 12%;
        @media ${d.maxSp} {
          width: 36%;
        }
      }
      &.logo_osaka_sangyo_kyoku {
        width: 12%;
        @media ${d.maxSp} {
          width: 34%;
        }
      }
      &.logo_kobe_city {
        width: 10%;
        @media ${d.maxSp} {
          width: 26%;
        }
      }
      &.logo_osaka_shoukou_kaigisho {
        width: 11%;
        @media ${d.maxSp} {
          width: 34%;
        }
      }
      &.logo_kobe_shoukou_kaigisho {
        width: 12.5%;
        @media ${d.maxSp} {
          width: 36%;
        }
      }
      &.logo-ksac {
        width: 10%;
        @media ${d.maxSp} {
          width: 27%;
        }
      }
      &.logo_osaka_innovation_hub {
        width: 16%;
        @media ${d.maxSp} {
          width: 40%;
        }
      }
      &.logo-ksii {
        width: 4.2%;
        @media ${d.maxSp} {
          width: 11%;
        }
      }
      &.logo_mitsui_sumitomo {
        width: 8.3%;
        @media ${d.maxSp} {
          width: 21%;
        }
      }
      &.logo_osaka_shinyo_kinko {
        width: 12%;
        @media ${d.maxSp} {
          width: 29%;
        }
      }
      &.logo_xport {
        width: 5%;
        @media ${d.maxSp} {
          width: 13%;
        }
      }
      &.logo_anchor {
        width: 10%;
        @media ${d.maxSp} {
          width: 27%;
        }
      }
      img {
        width: 100%;
        @media ${d.maxSp} {
          width: 100%;
        }
      }
    }
  }

  .title-box {
    .title-en-large {
      color: ${v.primaryColor24};
    }
    h2 {
      color: ${v.primaryColor24};
      font-size: 3.2rem;
      padding-bottom: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
  }
`;
