import React, { useContext, useState, Suspense, useRef } from "react";
import { useControls } from "leva";

import { Canvas } from "@react-three/fiber";
import { Html, SoftShadows, CameraControls } from "@react-three/drei";
import VanillaCameraControls from "camera-controls";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../UseMediaQuery";

import { YearContext } from "../../providers/YearProvider";
import { AnnounceContext } from "../../providers/AnnounceProvider";

import styled from "styled-components";

import COMMON_TO_ALL_YEARS from "../three/COMMON_TO_ALL_YEARS";
import FIELD_2021 from "../three/FIELD_2021";
import FIELD_2022 from "../three/FIELD_2022";
import FIELD_2023 from "../three/FIELD_2023";
import FIELD_2024 from "../three/FIELD_2024";
import FIELD_2025 from "../three/FIELD_2025";
import Lights from "../three/Lights";
import CopyRight from "../atoms/CopyRight";
import Head from "../../Head";
import Announce from "../organisms/Announce";

import ArchiveLinks from "../organisms/ArchiveLinks";
import { d } from "../../CssVariables";
import TopProjectAllLists from "../organisms/TopProjectAllLists";

const Home = () => {
  const navigate = useNavigate();
  const mq = useMediaQuery();

  // ref
  const cameraControlsRef = useRef();

  // context
  const { year } = useContext(YearContext);
  const { announce } = useContext(AnnounceContext);

  return (
    <>
      <Head title="JST START 神戸大学 大阪工業大学" />
      <SHome className={`year-${year}`}>
        {announce === true && <Announce />}
        <ArchiveLinks />
        <TopProjectAllLists />

        <Canvas colormanagement="true" shadows camera={(mq.isPC && { position: [0, 55, 60], fov: 48 }) || (mq.isSP && { position: [0, 130, 130], fov: 28 })}>
          <CameraControls
            makeDefault
            ref={cameraControlsRef}
            enabled={true}
            // active={false}
            // currentAction=

            // distance={120} // 現在の距離 (カメラの初期ポジションに影響するためoff)
            minDistance={45} // ドリーの最小値
            maxDistance={90} // ドリーの最大値
            // polarAngle={Math.PI * 0.25} // 縦方向のアングル (カメラの初期ポジションに影響するためoff)
            minPolarAngle={Math.PI * 0}
            maxPolarAngle={Math.PI * 0.35}
            // azimuthAngle={Math.PI * 0} // 横回転のアングル (カメラの初期ポジションに影響するためoff)
            minAzimuthAngle={Math.PI * 0}
            maxAzimuthAngle={Math.PI * 0}
            boundaryFriction={0.0}
            boundaryEnclosesCamera={true} // カメラの位置を境界で囲む必要があるかどうか
            dampingFactor={0.15} // マウス操作時の動きの減衰。1が減衰なし。小さい値ほどスムーズになる。
            draggingDampingFactor={0.25} // ドラッグ時の動きの減衰
            azimuthRotateSpeed={1.0}
            polarRotateSpeed={0.25}
            dollySpeed={0.25}
            truckSpeed={(mq.isPC && 0.5) || (mq.isSP && 2.0)} // トラックムーブの速度。初期値は2.0。
            dollyToCursor={true} // マウスポイントに向かってドリーするか否か
            // colliderMeshes={[]} //カメラと衝突するメッシュの配列
            infinityDolly={false}
            verticalDragToForward={true} // ドラッグで前面移動の動きをする。OrbitControlのscreenSpacePanning に似た動き
            restThreshold={0.0025} // カメラの速度が低下したときに「reset」イベントが発生するまでの時間を制御
            mouseButtons={{
              left: VanillaCameraControls.ACTION.TRUCK,
              shiftLeft: VanillaCameraControls.ACTION.TRUCK, // ???
              right: VanillaCameraControls.ACTION.ROTATE,
              wheel: VanillaCameraControls.ACTION.DOLLY, // 中ボタンの動き
              middle: VanillaCameraControls.ACTION.DOLLY, // 中ボタンの動き
            }}
            // ↓ 要検証
            touches={{
              one: VanillaCameraControls.ACTION.TOUCH_TRUCK,
              two: VanillaCameraControls.ACTION.TOUCH_ZOOM_TRUCK,
              // three: VanillaCameraControls.ACTION.TOUCH_DOLLY_OFFSET,
            }}
          />

          <Lights />

          {/* <SoftShadows size={5} focus={1} samples={10} /> */}
          {<SoftShadows size={60} focus={0} samples={10} />}

          <Suspense
            fallback={
              <Html center className="oswald weight500" style={{ color: "#ffffff" }}>
                Loading...
              </Html>
            }>
            {/* 重要: リンク処理関数はここで呼び出し、propsとしてシーンファイルに渡す */}
            <COMMON_TO_ALL_YEARS
              year={year}
              onClickUniversityKobe={() => navigate("/university/1")}
              onClickUniversityOit={() => navigate("/university/2")}
              cameraControlRef={cameraControlsRef}
            />

            {year === "2021" && (
              <FIELD_2021
                year={year}
                onClickProject1={() => navigate("/project-2020/1")}
                onClickProject2={() => navigate("/project-2020/2")}
                onClickProject3={() => navigate("/project-2020/3")}
                onClickProject4={() => navigate("/project-2020/4")}
                onClickProject5={() => navigate("/project-2020/5")}
                onClickProject6={() => navigate("/project-2020/6")}
                onClickProject7={() => navigate("/project-2020/7")}
                onClickProject8={() => navigate("/project-2020/8")}
                onClickProject9={() => navigate("/project-2020/9")}
                onClickProject10={() => navigate("/project-2020/10")}
                onClickProject11={() => navigate("/project-2020/11")}
                onClickProject12={() => navigate("/project-2020/12")}
                onClickProject13={() => navigate("/project-2020/13")}
                onClickProject14={() => navigate("/project-2020/14")}
                onClickProject15={() => navigate("/project-2020/15")}
                onClickProject16={() => navigate("/project-2020/16")}
                onClickDemoday={() => {
                  window.open(
                    "https://www.youtube.com/watch?v=8u20JpatnLc&t=162s&ab_channel=%E7%A5%9E%E6%88%B8%E5%A4%A7%E5%AD%A6%E3%83%BB%E5%A4%A7%E9%98%AA%E5%B7%A5%E6%A5%AD%E5%A4%A7%E5%AD%A6JSTSCORE%E4%BA%8B%E6%A5%AD",
                    "_blank"
                  );
                }} // ← 2021年度のYouTubeにリンク
                cameraControlRef={cameraControlsRef}
              />
            )}

            {year === "2022" && (
              <FIELD_2022
                year={year}
                onClickProject1={() => navigate("/project-2021/1")}
                onClickProject2={() => navigate("/project-2021/2")}
                onClickProject3={() => navigate("/project-2021/3")}
                onClickProject4={() => navigate("/project-2021/4")}
                onClickProject5={() => navigate("/project-2021/5")}
                onClickProject6={() => navigate("/project-2021/6")}
                onClickDemoday={() => navigate("/demoday2022")}
                cameraControlRef={cameraControlsRef}
              />
            )}

            {year === "2023" && (
              <FIELD_2023
                year={year}
                onClickProject1={() => navigate("/project-2022/1")}
                onClickProject2={() => navigate("/project-2022/2")}
                onClickProject3={() => navigate("/project-2022/3")}
                onClickProject4={() => navigate("/project-2022/4")}
                onClickProject5={() => navigate("/project-2022/5")}
                onClickProject6={() => navigate("/project-2022/6")}
                onClickProject7={() => navigate("/project-2022/7")}
                onClickDemoday={() => navigate("/demoday2023")}
                cameraControlRef={cameraControlsRef}
              />
            )}

            {year === "2024" && (
              <FIELD_2024
                year={year}
                onClickProject1={() => navigate("/project-2023/1")}
                onClickProject2={() => navigate("/project-2023/2")}
                onClickProject3={() => navigate("/project-2023/3")}
                onClickProject4={() => navigate("/project-2023/4")}
                onClickProject5={() => navigate("/project-2023/5")}
                onClickProject6={() => navigate("/project-2023/6")}
                onClickProject7={() => navigate("/project-2023/7")}
                onClickProject8={() => navigate("/project-2023/8")}
                onClickProject9={() => navigate("/project-2023/9")}
                onClickDemoday={() => navigate("/demoday2024")}
                cameraControlRef={cameraControlsRef}
              />
            )}
            {year === "2025" && (
              <FIELD_2025
                year={year}
                onClickProject1={() => navigate("/project-2024/1")}
                onClickProject2={() => navigate("/project-2024/2")}
                onClickProject3={() => navigate("/project-2024/3")}
                onClickProject4={() => navigate("/project-2024/4")}
                onClickProject5={() => navigate("/project-2024/5")}
                onClickProject6={() => navigate("/project-2024/6")}
                onClickDemoday={() => navigate("/demoday2025")}
                cameraControlRef={cameraControlsRef}
              />
            )}
          </Suspense>
        </Canvas>

        <CopyRight />
      </SHome>
    </>
  );
};
export default Home;

const SHome = styled.div`
  -webkit-touch-callout: none; /* 対象を長押しすることで現れるコールアウト表示を制御 */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; /* ユーザーがテキストを範囲選択できるかどうかを制御 */

  /* background-image: linear-gradient(-225deg, #800030 0%, #c10048 100%); */

  &.year-2021 {
    background: #037858;
  }
  &.year-2023 {
    background: linear-gradient(-225deg, rgba(181, 144, 0, 1) 0%, rgba(219, 180, 27, 1) 100%);
  }
  &.year-2024 {
    background: #18498b;
  }
  &.year-2025 {
    background: #088389;
  }

  position: fixed;
  width: 100%; // ウィンドウリサイズでcanvasを追従させるために必要
  height: 100%; // ウィンドウリサイズでcanvasを追従させるために必要
  overflow: hidden;
  overscroll-behavior-y: none;

  .copyright {
    position: fixed;
    z-index: 4;
    bottom: 36px;
    left: 40px;
    @media ${d.maxTab} {
      left: 0;
      bottom: 20px;
      bottom: 20px;
    }
  }
  .archive-links {
    position: fixed;
    z-index: 1;
    bottom: 36px;
    right: 36px;
    @media ${d.maxTab} {
      width: 100%;
      right: auto;
      bottom: 8px;
      left: auto;
    }
  }
`;
