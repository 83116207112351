const Projects = [
  {
    id: 1,
    url: "/project-2024/1",
    thumbnail: "/assets/images/project2024/01/thumbnail.jpg",
    adoptionYear: "2024年",
    category: "ライフサイエンス",
    title: "がんの生体内ライブイメージング医療を実現する顕微内視鏡技術の機能高度化",
    subtitle: null,
    authorUniversity: "神戸大学大学院",
    authorAffiliation: "科学技術イノベーション研究科 生体制御学講座",
    authorPosition: "特命助教",
    authorName: "後藤 俊志",
    authorEnName: "Toshiyuki GOTO",
    portrait: "/assets/images/project2024/01/portrait.jpg",
    video: {
      url: "https://youtu.be/ylBYz-rEIdA?si=OfCCmG8eQ-vA23nU",
      thumbnail: "/assets/images/project2024/01/video_thumbnail.jpg",
    },
    // document: "/assets/demoday2024/documents/presentation_01.pdf",
    // movieStart: "0:00:00〜",
    overviewText: [
      <p>
        がん悪性化の要因となる腫瘍環境の不均一性を考慮した創薬や治療技術開発には、生体において細胞レベルの情報を時系列で取得することが必要である。
        我々が開発した<span class="b">顕微内視鏡イメージング技術</span>は、生体深部まで細胞を低侵襲下に蛍光ライブイメージングすることを可能にする技術である。
      </p>,
      <p>
        本研究では当技術をより高度化させることで、<span class="b">医療機器（光バイオプシー）として発展させることを目的</span>としている。
      </p>,
    ],
    overviewImage: "/assets/images/project2024/01/fig01.jpg",
    buisinessModelText: [
      <p>
        本研究で開発した技術は特許性を十分有するものであると考えられるため、本研究期間において特許出願を計画している。また、本研究終了後に神戸大学発ベンチャーを設立することで、本研究で開発した特許技術を活用しながら実機販売を行う事業をスタートする予定である。
      </p>,
      <p>当事業では、まずは研究用実機および病理診断用機器として販売し、並行して医療機器承認の取得を目指すことを計画している。</p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <p>顕微内視鏡は蛍光を検出する技術であり、本技術を利用した光バイオプシーを臨床で実現するには、患者の体内で目的の細胞や分子を蛍光標識する必要がある。</p>,
      <p>
        しかし、医療現場における蛍光標識方法は、蛍光物質の全身投与や腫瘍組織への塗布が一般的であり、アレルギーなどの副作用や標識ムラ、非特異的な染色が課題である。
      </p>,
      <p>そこで、本研究ではこのような医療現場で想定される課題の解決に向けて、顕微内視鏡技術を改良することによる高度化を目的とする。</p>,
    ],
    activityPlanImage: null,
  },
  {
    id: 2,
    url: "/project-2024/2",
    thumbnail: "/assets/images/project2024/02/thumbnail.jpg",
    adoptionYear: "2024年",
    category: "ものづくり",
    title: "カーボンニュートラルに貢献する超速ゼニゴケ工学を活かしたビジネス創出",
    subtitle: "〜高栄養食品と合成生物学プラットフォーム〜",
    authorUniversity: "神戸大学大学院",
    authorAffiliation: "理学研究科",
    authorPosition: "教授",
    authorName: "石崎 公庸",
    authorEnName: "Kimitsune ISHIZAKI",
    portrait: "/assets/images/project2024/02/portrait.jpg",
    video: {
      url: "https://youtu.be/4uHak4JglwA?si=sPR2JiMNYNCz349v",
      thumbnail: "/assets/images/project2024/02/video_thumbnail.jpg",
    },
    // document: "/assets/demoday2024/documents/presentation_01.pdf",
    // movieStart: "0:00:00〜",
    overviewText: [
      <p>CO2を吸収して有機化合物を合成する植物バイオものづくり</p>,
      <p>
        その切り札として<span class="b">コケ植物ゼニゴケ</span>に着目
      </p>,
      <p>食用ゼニゴケ新品種の確立と栽培プラント開発を推進</p>,
      <p>年度内の法人化に向けた事業計画を策定</p>,
    ],
    overviewImage: "/assets/images/project2024/02/fig01.jpg",
    buisinessModelText: [
      <p>
        自社開発した食材好適ゼニゴケコア品種と専用栽培システムのノウハウを元に、植物工場運営企業や食品加工企業と業務提携し、非組換え食用ゼニゴケ（LCPUFA含有）を製造加工し、顧客の食品ネット通販企業や総合食品スーパーへの販売を行う。
      </p>,
      <p>３年後からは高度な遺伝子組換えを施した高付加価値物質高生産ゼニゴケによるバイオものづくり事業を展開する。</p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <ol>
        <li>
          食用ゼニゴケ（非GM）事業化に向けた研究開発
          <br />
          食用ゼニゴケ品種の確立・・・食味と安全性改善に関する定量的データの取得
          <br />
          極短仮根やLCPUFA高生産など有用形質を交配育種により早期に作出
          <br />
          高栄養食品としての用途開発・・・レシピ開発、サンプル提供、試食会の開催
        </li>
        <li>ゼニゴケの小規模栽培プラントの開発（月産10kgの生産規模を達成）</li>
        <li>
          起業に向けた事業計画策定と経営チーム組織化
          <br />
          2023年秋にビズリーチで募集した経営人材を核に推進
        </li>
      </ol>,
    ],
    activityPlanImage: "/assets/images/project2024/02/fig02.jpg",
  },
  {
    id: 3,
    url: "/project-2024/3",
    thumbnail: "/assets/images/project2024/03/thumbnail.jpg",
    adoptionYear: "2024年",
    category: "ライフサイエンス",
    title: "安全で地球に優しいKobe培養ビーフの生産技術の構築と培養装置の開発",
    subtitle: null,
    authorUniversity: "神戸大学大学院",
    authorAffiliation: "保健学研究科",
    authorPosition: "教授",
    authorName: "藤野 英己",
    authorEnName: "Hidemi FUJINO",
    portrait: "/assets/images/project2024/03/portrait.jpg",
    video: {
      url: "https://youtu.be/Vk8vz7gauxo?si=LVGEKDOtcRJ3i9vU",
      thumbnail: "/assets/images/project2024/03/video_thumbnail.jpg",
    },
    // document: "/assets/demoday2024/documents/presentation_01.pdf",
    // movieStart: "0:00:00〜",
    overviewText: [
      <p>
        代替肉は食品添加物から作られるウルトラプロセスフードであり、安全性が懸念されている。そこで、安全で地球に優しい新しい技術で培養肉の生産法を開発する。本技術は細胞の自然な増殖能力を最大限に引き出す環境制御による細胞増殖の効率化で、新たに添加物を使用せず、
        安全性を担保して、生産コストを減少させることができる。
      </p>,
      <p>
        本技術を基盤として、新たな培養肉の生産法の提案や新規の培養肉生産装置の販売市場化を目指す。本提案では筋肉から抽出した細胞を培養、培養肉生産装置（バイオリアクター）のプロトタイプを開発し、製品化を目指す。
      </p>,
    ],
    overviewImage: "/assets/images/project2024/03/fig01.jpg",
    buisinessModelText: [
      <p>
        本技術シーズを活用できるビジネスとして、新規細胞培養装置の開発製造、培養肉の製造のほか、再生医療への応用が挙げられる。医薬品開発や再生医療分野への新規細胞培養装置として開発製造をして、起業を目指す。
      </p>,
      <p>
        また、将来的に人口増加による食料問題や環境問題を背景として食用動物の飼育から培養肉生産へシフトしていくことが予想される。本技術シーズは細胞増殖の効率化を自然な方法で安全に行うことができる。先行する他社とも協力体制を含めて、検討し、培養肉の製造へ発展を進める。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <p>本研究開発の活動計画は新規バイオリアクターを開発し、培養肉生産へ応用する技術を開発する。</p>,
      <ol>
        <li>細胞を大量増殖させるためのバイオリアクターを開発し、プロトタイプを作製する</li>
        <li>牛個体の初代培養での培養の効率化を検証とこれまでと同等の培養効率化を確認する</li>
        <li>多量培養により肉眼で見える大きさまで細胞増殖をさせ、肉量が多いことを肉眼的に確認できることを目標とする</li>
        <li>開発するバイオリアクターでの検証が成功した際には、事業を展開する基盤となる特許を出願する </li>
      </ol>,
    ],
    activityPlanImage: "/assets/images/project2024/03/fig02.jpg",
  },
  {
    id: 4,
    url: "/project-2024/4",
    thumbnail: "/assets/images/project2024/04/thumbnail.jpg",
    adoptionYear: "2024年",
    category: "ライフサイエンス",
    title: "食道癌術後吻合部狭窄を解除する生体内分解性合金ステントの開発",
    subtitle: null,
    authorUniversity: "神戸大学大学院",
    authorAffiliation: "医学研究科 医療創成工学専攻",
    authorPosition: "特命准教授",
    authorName: "鷹尾 俊達",
    authorEnName: "Toshitatsu TAKAO",
    portrait: "/assets/images/project2024/04/portrait.jpg",
    video: {
      url: "https://youtu.be/uPwzBazIGrE?si=d_UAqVGma_OH7i_J",
      thumbnail: "/assets/images/project2024/04/video_thumbnail.jpg",
    },
    // document: "/assets/demoday2024/documents/presentation_01.pdf",
    // movieStart: "0:00:00〜",
    overviewText: [
      <p>
        上部消化管内の低pH下において緩徐に分解する合金を開発し、内視鏡的に拡張しても何度も再狭窄する食道癌術後吻合部を広がったまま治癒させる新型ステントを開発する。
      </p>,
      <p>必要な期間耐縮径力を発揮する組成や構造を持ち、脱落防止機構を兼ね備えたステントを開発し、特許を取得する。</p>,
      <img src="/assets/images/project2024/04/fig01.jpg" alt="" />,
    ],
    overviewImage: null,
    buisinessModelText: [
      <p>
        本プロジェクトでは、酸性環境下において経時的に必要な耐縮径力を維持し脱落防止構造を有するステントを開発し、非臨床試験での機能検証後、事業化において競争優位となる特許出願を行う。
      </p>,
      <p>
        新型ステントは医療機器クラスⅢとなるため、薬事承認・保険償還に向け、医療機器製造販売業者との協業を視野に入れた活動を行い、スタートアップの設立を検討する。
      </p>,
    ],
    buisinessModelImage: null,
    activityPlanText: [<img src="/assets/images/project2024/04/fig02.jpg" alt="" />],
    activityPlanImage: null,
  },
  {
    id: 5,
    url: "/project-2024/5",
    thumbnail: "/assets/images/project2024/05/thumbnail.jpg",
    adoptionYear: "2024年",
    category: "ものづくり",
    title: "エネルギーハーベスティングのための高効率かつ高感度なマイクロ波電力伝送受信IC及びシステムの開発",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "工学部 電気電子システム工学科",
    authorPosition: "特任准教授",
    authorName: "金城 良守",
    authorEnName: "Yoshimori KANESHIRO",
    portrait: "/assets/images/project2024/05/portrait.jpg",
    video: {
      url: "https://youtu.be/WRdp3bUNqKA?si=AtX9ym9wDg4dP3Rx",
      thumbnail: "/assets/images/project2024/05/video_thumbnail.jpg",
    },
    // document: "/assets/demoday2024/documents/presentation_01.pdf",
    // movieStart: "0:00:00〜",
    overviewText: [
      <ul>
        <li>
          現在主流の無線電力伝送技術である電磁誘導方式では、到達距離は、スマートフォンのワイヤレス充電では1cm以下であり、その変換効率も60％程度と低いのが問題である。
        </li>
        <li>
          “真”のワイヤレス社会を実現するために、安全なマイクロ波による電力伝送技術の開発を加速させる。集積回路設計技術により、感度と効率を高め、電力をより遠くに届け、バッテリーをより早く充電する回路とシステムを開発する。
        </li>
      </ul>,
    ],
    overviewImage: "/assets/images/project2024/05/fig01.jpg",
    buisinessModelText: [
      <p>ビジネスモデルとして以下の２つを想定</p>,
      <ul>
        <li>IPライセンス提供：回路、ICレイアウト、モジュール構造・構成、システム</li>
        <li>最終製品の共同開発企業：通信ICメーカー、通信会社、スマートフォンメーカー、医療機器メーカーなど</li>
      </ul>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <p>[内容]</p>,
      <ul>
        <li>高感度、高効率マイクロ波電力受電回路の開発 → 集積回路と基板の設計、測定を大学内ラボで実施</li>
        <li>アンテナ設計技術の習得と、受電回路とアンテナを一体化したレクテナの開発</li>
        <li>既存技術の調査と特許出願</li>
      </ul>,
      <br />,
      <p>[計画]</p>,
      <ul>
        <li>2024年8月に独自回路の特性評価を実施。その結果が所望値であれば、顧客となる可能性のある企業にPRと共同開発を提案する活動を開始</li>
      </ul>,
    ],
    activityPlanImage: null,
  },
  {
    id: 6,
    url: "/project-2024/6",
    thumbnail: "/assets/images/project2024/06/thumbnail.jpg",
    adoptionYear: "2024年",
    category: "ものづくり",
    title: "広視域ライトフィールドカメラの高精細化による表面欠陥検出システムの開発",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "情報科学部 情報メディア学科",
    authorPosition: "教授",
    authorName: "河北 真宏",
    authorEnName: "Masahiro KAWAKITA",
    portrait: "/assets/images/project2024/06/portrait.jpg",
    video: {
      url: "https://youtu.be/9shz3DLUuTE?si=PZV3iU2IjFSYzHMP",
      thumbnail: "/assets/images/project2024/06/video_thumbnail.jpg",
    },
    // document: "/assets/demoday2024/documents/presentation_01.pdf",
    // movieStart: "0:00:00〜",
    overviewText: [
      <p>
        製品表面の欠陥検査を自動化する技術を開発する。現在、製品の欠陥検査では、一般的なカメラや形状計測器が使用されているが、欠陥の自動検出が難しく、目視検査している製品も多い。本技術シーズである広視域ライトフィールドカメラは１台のカメラで様々な位置から見た多視点画像が得られるため、目視検査の場合と同様の画像検査が可能である。
      </p>,
      <p>本課題では、微小欠陥の自動検出に必要となるカメラの高精細化を実現し、検査システムの試作により技術検証を行い、早期の起業を目指す。</p>,
    ],
    overviewImage: "/assets/images/project2024/06/fig01.jpg",
    buisinessModelText: [
      <ul>
        <li>製品の表面欠陥検出を自動化する技術を開発し、検査装置を製造・販売するベンチャー企業を目指す。</li>
        <li>撮影画像より欠陥を検出するために必要となる画像処理ソフトも独自に開発する。</li>
        <li>製造メーカと連携して、様々な検査対象の製品に特化した装置を開発することで市場拡大を可能とする。</li>
      </ul>,
    ],
    buisinessModelImage: null,
    activityPlanText: [
      <ol>
        <li>光線撮影の高精細化 ・現在の約5倍（約60万画素）の解像度の映像撮影を実現</li>
        <li>撮影・表示システムの性能評価と改善 ・リアルタイム３D撮影と表示システムの試作と性能評価</li>
        <li>要素映像処理技術の確立 ・撮影映像より多視点映像を生成処理するアルゴリズム開発</li>
        <li>実用化検証と市場開拓 ・市場調査と知的財産の確保、技術検証、本技術のアピール</li>
      </ol>,
    ],
    activityPlanImage: "/assets/images/project2024/06/fig02.jpg",
  },
];

export default Projects;
