import styled from "styled-components";
import { d, v } from "../../../CssVariables";

const TimeSchedule = () => {
  return (
    <STimeSchedule id="timeSchedule">
      <div className="content-over-box"></div>

      <div className="inner">
        <div className="title-box">
          <div className="title-en-large title-en center">TIME SCHEDULE</div>
          <h2 className="center">タイムスケジュール</h2>
        </div>

        <div className="desc">
          <dl>
            <dt>13:30</dt>
            <dd>
              <b>開催概要・プログラム説明</b>
            </dd>
            <dt>13:45</dt>
            <dd>
              <b>[発表01]</b>
              <br />
              <b>
                カーボンニュートラルに貢献する超速ゼニゴケ工学を活かしたビジネス創出
                <br />
                〜高栄養食品と合成生物学プラットフォーム〜
              </b>
              <br />
              <span className="katagaki">神戸大学 大学院理学研究科 教授</span>石崎 公庸
            </dd>

            <dt>14:01</dt>
            <dd>
              <b>[発表02]</b>
              <br />
              <b>がんの生体内ライブイメージング医療を実現する顕微内視鏡技術の機能高度化</b>
              <br />
              <span className="katagaki">神戸大学 大学院科学技術イノベーション研究科 生体制御学講座 特命助教</span>後藤 俊志
            </dd>

            <dt>13:17</dt>
            <dd>
              <b>[発表03]</b>
              <br />
              <b>安全で地球に優しいKobe培養ビーフの生産技術の構築と培養装置の開発</b>
              <br />
              <span className="katagaki"> 神戸大学 大学院保健学研究科 教授</span>藤野 英己
            </dd>

            <dt>14:32</dt>
            <dd>休憩</dd>

            <dt>14:47</dt>
            <dd>
              <b>[発表04]</b>
              <br />
              <b>エネルギーハーベスティングのための 高効率かつ高感度なマイクロ波電力伝送受信IC及びシステムの開発</b>
              <br />
              <span className="katagaki">大阪工業大学 工学部 電気電子システム工学科 特任准教授</span>金城 良守
            </dd>

            <dt>15:03</dt>
            <dd>
              <b>[発表05]</b>
              <br />
              <b>広視域ライトフィールドカメラの高精細化による表面欠陥検出システムの開発</b>
              <br />
              大阪工業大学 情報科学部 情報メディア学科 教授<span className="katagaki">河北 真宏</span>
            </dd>

            <dt>15:19</dt>
            <dd>
              <b>[発表06]</b>
              <br />
              <b>食道癌術後吻合部狭窄を解除する生体内分解性合金ステントの開発</b>
              <br />
              <span className="katagaki">神戸大学 大学院医学研究科 医療創成工学専攻 特命准教授</span>鷹尾 俊達
            </dd>

            <dt>15:34</dt>
            <dd>
              <b>閉会挨拶</b>
            </dd>
            <dt>15:40</dt>
            <dd>
              <b>終了予定</b>
            </dd>
          </dl>

          {/* <p style={{ marginTop: "10px" }}>※ 発表内容・発表順序は予告なく変更する場合があります。</p> */}
        </div>
      </div>
    </STimeSchedule>
  );
};
export default TimeSchedule;

const STimeSchedule = styled.section`
  position: relative;
  margin: 0;
  padding: 8vw 6%;
  background-color: #fff;
  @media ${d.minLaptop} {
    padding: 6vw 16vw;
  }
  .content-over-box {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: 100%;

    background-color: transparent;
  }

  .inner {
    position: relative;
    z-index: 2;
  }

  .title-box {
    .title-en {
      color: ${v.primaryColor24};
    }
    h2 {
      color: ${v.primaryColor24};
      font-size: 3.2rem;
      padding-bottom: 0;
      @media ${d.maxTab} {
        font-size: 2.4rem;
      }
    }
  }
  .desc {
    width: 900px;
    margin: 0 auto;
    padding: 0 30px 30px 30px;
    /* background-color: rgba(255, 255, 255, 0.95); */
    @media ${d.maxTab} {
      width: 100%;
      padding: 10px;
    }

    dl {
      dt {
        flex-basis: 20%;
        background-color: transparent;
        line-height: 1.5em;
      }
      dd {
        flex-basis: 80%;
        background-color: transparent;
        letter-spacing: 0;
        line-height: 1.5em;
      }
      span.katagaki {
        display: inline-block;
        margin-right: 1em;
      }
    }
  }

  p {
    color: #fff;
  }
`;
