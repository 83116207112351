import { useEffect } from "react";
import BalloonBase from "../BalloonBase";

const BalloonDemoday = (props) => {
  const { hoverDemoday } = props;
  useEffect(() => {
    document.body.style.cursor = hoverDemoday ? "pointer" : "default";
  }, [hoverDemoday]);

  return (
    <BalloonBase whichHover={hoverDemoday} position={[0, 0, 0]}>
      DEMO DAY 2024
    </BalloonBase>
  );
};
export default BalloonDemoday;
